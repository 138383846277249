import './styles/Notificacao.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getNotificacoes } from '../../actions/notificacaoActions';
import NotificacaoItemList from '../Notificacao/NotificacaoItemList';
import NotificacaoVazio from './NotificacaoVazio';

const configureListeners = () => {
  const dropdown = document.getElementById('dropdownMenu');

  dropdown.addEventListener('click', () => {
    const parent = dropdown.parentNode;
    if (parent.className.includes('open')) parent.classList.remove('open');
    else parent.classList.add('open');
  });

  window.addEventListener('click', event => {
    const parent = dropdown.parentNode;
    const { parentNode, className } = event.target;
    if (
      parentNode.id !== dropdown.id &&
      typeof className === 'string' &&
      !className.includes('dot') &&
      !className.includes('loadmore')
    )
      parent.classList.remove('open');
  });

  return dropdown;
};

const Notificacao = ({
  notificacoes,
  notificacoesNaoLidas,
  getNotificacoes,
  totalNotificacoes,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const dropdown = configureListeners();

    return function cleanup() {
      dropdown.removeEventListener('click', () => {});
      window.removeEventListener('click', () => {});
    };
  }, []);

  useEffect(() => {
    getNotificacoes(currentPage);
  }, [currentPage]);

  const hideLoadMore = currentPage * 5 >= totalNotificacoes;

  return (
    <div className="notificacao-icon-container">
      <div className="dropdown c-pointer ">
        <div
          className="dropdown-toggle"
          id="dropdownMenu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <i className="fa fa-bell grey notificacao-badge" data-badge={notificacoesNaoLidas}></i>
        </div>
        <ul
          id="dropdown-notificacao"
          className="dropdown-menu mt-1 w-400 fix-h-337 xs-scroll"
          aria-labelledby="dropdownMenu"
        >
          <li className="dropdown-header">Notificações</li>
          {totalNotificacoes > 0 ? (
            <React.Fragment>
              <NotificacaoItemList dataSource={notificacoes} />
            </React.Fragment>
          ) : (
            <NotificacaoVazio />
          )}

          {!hideLoadMore && (
            <li>
              <button
                className="btn btn-secondary btn-block loadmore"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                Ver mais
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  notificacoes: state.notificacao.notificacoes,
  totalNotificacoes: state.notificacao.totalNotificacoes,
  notificacoesNaoLidas: state.notificacao.notificacoesNaoLidas,
});

export default connect(mapStateToProps, { getNotificacoes })(Notificacao);
