import React from 'react';
import playstore from './assets/img/footer/playstore.png';
import applestore from './assets/img/footer/applestore.png';


const Footer = () => (
  <div className="footer fixed">
    <div className="pull-right">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="mr-1"
        href="https://play.google.com/store/apps/details?id=br.com.tron.tgcapp&hl=pt_BR">
        <img alt="play" src={playstore} />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="mr-1"
        href="https://apps.apple.com/br/app/tron-smart/id1445014197">
        <img alt="apple" src={applestore} />
      </a>
    </div>
    <div>
      <label>Copyright</label> Tron Informática &copy; {new Date().getFullYear()}
    </div>
  </div>
);

export default Footer;
