import React from 'react'

export default function(props) {
  return <li>
        <a href={props.path}>
            {
                props.icon &&
                <i className={`fa fa-${props.icon}`} />
            }
            { props.tree ?
                props.label
            :
                <span className="nav-label">{props.label}</span>
            }
        </a>
    </li>
}
