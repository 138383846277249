import {
  ATUALIZA_LISTA_RESPONSAVEIS_SETORES_EMPRESA,
  CBR_SET_PLANO_CONTRATADO,
  FETCH_EMPRESA_SUCCESS,
  FETCH_EMPRESAS_SUCCESS,
  FETCH_RESPONSAVEIS_SETORES_EMPRESA_SUCCESS,
  LIMPAR_LISTA_RESPONSAVEIS_SETORES_EMPRESA,
  SET_EMPRESAS_COLABORADOR,
  FETCH_TODOS_COLABORADORES_SUCCESS,
} from '../actions/types';

const INITIAL_STATE = {
  escritorios: [],
  empresas: [],
  tipoInscricaoAcao: {
    mascara: '',
    desabilitaInscricao: true,
    desabilitaTipoInscricao: false,
    exibeCadastroCompleto: true,
    exibeDadosParaCNPJ: true,
    label: 'Razão Social',
  },
  desabilitaTipoInscricao: false,
  carregandoEmpresas: false,
  qtdEmpresasInativas: 0,
  qtdEmpresasAtivas: 0,
  qtdEmpresas: 0,
  desabilitaForm: false,
  qtdPlanoContratado: 0,
  empresa: {},
  responsaveisSetoresAtendimento: [],
  empresasColaborador: [],
  colaboradores: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ESTADO_INICIAL':
      return {
        escritorios: [],
        empresas: [],
        tipoInscricaoAcao: {
          mascara: '',
          desabilitaInscricao: true,
          desabilitaTipoInscricao: false,
          exibeCadastroCompleto: true,
          exibeDadosParaCNPJ: true,
          label: 'Razão Social',
        },
        desabilitaTipoInscricao: false,
        carregandoEmpresas: false,
        qtdEmpresas: 0,
        qtdEmpresasAtivas: 0,
        qtdEmpresasInativas: 0,
        desabilitaForm: false,
        qtdPlanoContratado: 0,
        responsaveisSetoresAtendimento: [],
        empresasColaborador: [],
        empresa: {},
      };
    case 'GET_ESCRITORIOS':
      return { ...state, escritorios: action.payload.data.escritorios };
    case 'GET_EMPRESAS':
      return { ...state, empresas: action.payload.data.empresas };
    case 'SET_MASCARA_INSCRICAO':
      return { ...state, tipoInscricaoAcao: action.payload };
    //Empresas
    case 'INICIAR_EMPRESAS':
      return { ...state, carregandoEmpresas: true };
    case 'ENCERRA_CARREGAMENTO_EMPRESAS':
      return { ...state, carregandoEmpresas: false };
    case 'COMPLETADO_EMPRESAS':
      return { ...state, empresas: action.result.data.empresas, carregandoEmpresas: false };
    case 'COMPLETADO_EMPRESAS_INICIAL':
      return { ...state, carregandoEmpresas: false, qtdEmpresas: action.result.data.total };
    case 'SET_QTD_EMPRESAS_INATIVAS':
      return { ...state, qtdEmpresasInativas: action.payload };
    case 'SET_QTD_EMPRESAS_ATIVAS':
      return { ...state, qtdEmpresasAtivas: action.payload };
    case 'SET_EMPRESAS_CARTEIRA_CLIENTES':
      return { ...state, empresas: action.payload.empresas };
    case 'SET_DESABILITA_FORM_EMPRESA_CONTABIL':
      return { ...state, desabilitaForm: action.payload };
    case FETCH_EMPRESAS_SUCCESS:
      return { ...state, empresas: action.payload };
    case CBR_SET_PLANO_CONTRATADO:
      return { ...state, qtdPlanoContratado: action.payload };
    case FETCH_EMPRESA_SUCCESS:
      return { ...state, empresa: action.payload };
    case FETCH_RESPONSAVEIS_SETORES_EMPRESA_SUCCESS:
    case ATUALIZA_LISTA_RESPONSAVEIS_SETORES_EMPRESA:
      return { ...state, responsaveisSetoresAtendimento: action.payload };
    case LIMPAR_LISTA_RESPONSAVEIS_SETORES_EMPRESA:
      return { ...state, responsaveisSetoresAtendimento: [] };
    case SET_EMPRESAS_COLABORADOR:
      return { ...state, empresasColaborador: action.payload };
    case FETCH_TODOS_COLABORADORES_SUCCESS:
      return { ...state, colaboradores: action.payload };
    default:
      return state;
  }
}
