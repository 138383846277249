const setRotasNaoPesquisaveis = rota => {
    let rotas = {
        '/gestao': () => false,
        'api/colaborador-empresas':() => false,
        '/pessoas':() => false,
        '/inquilino/painel': () => false,
        '/grupo-obrigacoes': () => false,
        '/notificacoes': () => false,

        'default': () => true
    }

    return (rotas[rota] || rotas['default'])();
}

export default setRotasNaoPesquisaveis;