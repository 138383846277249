const INITIAL_STATE = {
  escritorios: [],
  tipoInscricaoAcao: {},
  desabilitaTipoInscricao: false,
  logoTipo: null,
  firstEscritorio: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_ESCRITORIOS':
      return {
        ...state,
        escritorios: action.payload.data.escritorios,
        firstEscritorio: action.payload.data.escritorios[0],
      };
    case 'SET_MASCARA_INSCRICAO':
      return { ...state, tipoInscricaoAcao: action.payload };
    case 'SET_LOGOTIPO':
      return { ...state, logoTipo: action.payload };
    default:
      return state;
  }
}
