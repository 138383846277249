import { UPDATE_PESSOA_LOGADA } from '../actions/types';

const INITIAL_STATE = {
  pessoaLogada: {
    nome: null,
    foto: null,
    tipo: null,
    empresa: null,
    isEscritorio: false,
    empresaId: null,
    id: null,
    grupoUsuario: null,
    grupoUsuarioId: null,
    pessoaUsuarioJaExiste: false,
    status: false,
  },
  pessoaJaLogada: false,
  usuariosDisponiveis: [],
  carregandoUsuario: true,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_PESSOA_LOGADA':
      return {
        ...state,
        pessoaLogada: {
          ...action.payload,
        },
        carregandoUsuario: false,
      };
    case 'SET_ESTADO_INICIAL_USUARIO_LOGADO':
      return {
        pessoaLogada: {},
      };
    case 'USER_SET_PESSOA_JA_LOGADA':
      return { ...state, pessoaJaLogada: action.payload };
    case 'USER_GET_USUARIOS_DISPONIVEIS':
      return { ...state, usuariosDisponiveis: action.payload.data.usuariosDisponiveis };
    case UPDATE_PESSOA_LOGADA:
      return { ...state, pessoaLogada: action.payload };
    default:
      return state;
  }
}
