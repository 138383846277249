export function isCPF(value) {
    return value.replace(/[^\d]+/g,'').length === 11;
}

export function isCNPJ(value){
    return value.replace(/[^\d]+/g,'').length === 14;
}

export function isMatriz(value){
   return isCNPJ(value) && value.match(/[\d]{4}/)[0] === "0001";
}
