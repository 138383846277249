import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import loginReducer from './loginReducer';
import usuarioReducer from './usuarioReducer';
import defaultReducer from './defaultReducer';
import empresaReducer from './empresaReducer';
import empresaObrigacaoReducer2 from './empresaObrigacaoReducer2';
import escritorioReducer from './escritorioReducer';
import grupoObricaoReducer from './grupoObrigacaoReducer';
import dashboardReducer from './dashboardReducer';
import pessoaEscritorioReducer from './pessoaEscritorioReducer';
import gestaoReducer from './gestaoReducer';
import permissaoAcessoReducer from './permissaoAcessoReducer';
import topHeaderReducer from './topHeaderReducer';
import auditoriaReducer from './auditoriaReducer';
import setorAtendimentoReducer from './setorAtendimentoReducer';
import certidaoNegativaDebitoReducer from './certidaoNegativaDebitoReducer';
import analiseAtividadesReducer from './analiseAtividadesReducer';
import gestaoDocumentoReducer from './gestaoDocumentoReducer';
import rotaReducer from './rotasReducer';
import notificacaoReducer from './notificacaoReducer';

// Velharia
import PessoaEmpresaReducer from './pessoaEmpresaReducer';
import SolicitacaoReducer from './solicitacaoReducer';
import mensagensPadraoReducer from './mensagensPadraoReducer';
import AtividadeReducer from './atividadeReducer';
import GenericReducer from '../views/generic/genericReducer';
import filaTrabalhoReducer from './filaTrabalhoReducer';

const rootReducer = combineReducers({
  analiseAtividades: analiseAtividadesReducer,
  atividade: AtividadeReducer,
  auditoria: auditoriaReducer,
  certidaoNegativa: certidaoNegativaDebitoReducer,
  dashboard: dashboardReducer,
  default: defaultReducer,
  empresa: empresaReducer,
  empresaObrigacao2: empresaObrigacaoReducer2,
  escritorio: escritorioReducer,
  form: reduxFormReducer,
  generic: GenericReducer,
  gestao: gestaoReducer,
  gestaoDocumento: gestaoDocumentoReducer,
  grupoObrigacao: grupoObricaoReducer,
  logged: loginReducer,
  mensagensPadrao: mensagensPadraoReducer,
  notificacao: notificacaoReducer,
  permissaoAcesso: permissaoAcessoReducer,
  pessoaEmpresa: PessoaEmpresaReducer,
  pessoaEscritorio: pessoaEscritorioReducer,
  rota: rotaReducer,
  setorAtendimento: setorAtendimentoReducer,
  solicitacao: SolicitacaoReducer,
  toastr: toastrReducer,
  topHeader: topHeaderReducer,
  usuario: usuarioReducer,
  filaTrabalho: filaTrabalhoReducer,
});

export default rootReducer;
