import { FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
    sinteseCertidoes: [],
    certidoes: [],
    status: [],
    carregandoCertidoes: false,
    // Modals
    showModalDadosDaCertidao: false,
    //Movimentação CND
    carregandoMovimentacaoCND: false,
    movimentacoesCND: [],
    // Modal Dados da Certidão
    publicarParaCliente: true,
    empresa: {},
    certidoesDisponiveis: [],
    situacoesCertidao: [],
    statusCertidao: [],
    diasAnteciparAtualizacao: 3,
    diasNovaConsultaPositiva: 15,
    empresaSelecionada: null,
    dadosGeracaoCND: {},
    dadosParametrizacaoCND: {},
    captcha: '',
    htmlCaptcha: '',
    carregandoCapcha: false,
    gerandoCertidao: false,
    todasEmpresasAtivas: [],
    shLoading: false,
    movCNDSelecionada: {},
    anexos: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ESTADO_INICIAL':
            return { ...INITIAL_STATE };
        case 'GET_ESCRITORIOS':
            return { ...state, certidoes: action.payload.data.certidoes }
        case 'GET_STATUS':
            return { ...state, empresas: action.payload.data.status }
        //Certidoes
        case 'INICIAR_CERTIDOES':
            return { ...state, carregandoCertidoes: true }
        case 'COMPLETADO_CERTIDOES':
            return { ...state, sinteseCertidoes: action.payload.sintese, carregandoCertidoes: false }
        case 'SHOW_MODAL_DADOSDACERTIDAO':
            return { ...state, showModalDadosDaCertidao: action.payload }
        case 'SHOW_MODAL_UPLOAD_MANUAL':
            return { ...state, showModalUploadManual: action.payload }
        case 'SHOW_MODAL_UPLOAD_MANUAL_EDIT':
            return { ...state, showModalUploadManualEdit: action.payload }
        case "SET_ANEXOS":
            return { ...state, defaultFileList: action.payload };
        case "SET_CND_ANEXO_STRING":
            return { ...state, anexo: action.payload };
        case "REMOVE_ARQUIVO_UPLOAD":
            return {
                ...state,
                anexos: state.anexos.filter(file => file.uid !== action.payload.uid)
            };
        case "SET_ANEXO_CERTIDAO":
            return { ...state, anexos: [...state.anexos, action.payload] };
        //Movimentação CND
        case 'INICIAR_CARREGAMENTO_MOV_CND':
            return { ...state, carregandoMovimentacaoCND: true }
        case 'COMPLETADO_CARREGAMENTO_MOVIMENTACOES_CND':
            return { ...state, movimentacoesCND: action.payload.data.movimentacoesCND, carregandoMovimentacaoCND: false }
        case 'COMPLETADO_CARREGAMENTO_MOV_CND':
            return { ...state, carregandoMovimentacaoCND: false }
        //Modal Dados da Certidão
        case 'PUBLICAR_PARA_CLIENTE':
            return { ...state, publicarParaCliente: action.payload }
        case 'DIAS_ANTECIPAR_ATUALIZACAO':
            return { ...state, diasAnteciparAtualizacao: action.payload }
        case 'PUBLICAR_AUTOMATICAMENTE':
            return { ...state, publicarAutomaticamente: action.payload }
        case FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS:
        case 'SET_NOVAS_MOVIMENTACOES_CERTIDOES':
            return { ...state, movimentacoesCND: action.payload }
        case 'SET_EMPRESA_PARAM_CND':
            return { ...state, empresa: action.payload }
        case 'GET_CERTIDOES_DISPONIVEIS':
            return { ...state, certidoesDisponiveis: action.payload.data.certidoesNegativas }
        case 'GET_TIPO_MANUAL':
            return { ...state, tipoManual: action.payload.data.certidoesNegativas }
        case 'SET_LISTA_ANEXOS':
            return{ ...state, anexos: action.payload}
        case 'GET_SITUACOES_CERTIDOES_NEGATIVAS':
            return { ...state, situacoesCertidao: action.payload.data }
        case 'GET_SITUACOES_CERTIDOES_NEGATIVAS_MANUAL':
            return { ...state, situacoesCertidaoManual: action.payload.data }
        case 'GET_STATUS_CERTIDOES_NEGATIVAS_EMPRESAS':
            return { ...state, statusCertidao: action.payload.data }
        case 'SET_DADOS_PARA_GERACAO_CND':
            return { ...state, dadosGeracaoCND: action.payload }
        case 'GET_DADOS_PARAMETRIZACAO_CND':
            return { ...state, dadosParametrizacaoCND: action.payload.data }
        case 'SET_CAPTCHA':
            return { ...state, captcha: action.payload }
        case 'INICIA_CARGA_CAPCHA':
            return { ...state, carregandoCapcha: true }
        case 'GET_HTML_CAPTCHA':
            return { ...state, htmlCaptcha: action.payload.data.htmlCaptcha, carregandoCapcha: false }
        case 'INCIANDO_GERACAO_CND':
            return { ...state, gerandoCertidao: true }
        case 'FINALIZANDO_GERACAO_CND':
            return { ...state, gerandoCertidao: false }
        case 'GET_TODAS_EMPRESAS_ATIVAS_CND':
            return { ...state, todasEmpresasAtivas: action.payload.data.empresas }
        case 'SET_LOADING':
            return { ...state, shLoading: action.payload }
        case 'SELECIONA_MOV_CND':
            return {...state, movCNDSelecionada: state.movimentacoesCND.find(movCND => movCND.id === action.payload)};
        default:
            return state
    }
}
