const INITIAL_STATE = {
    grupoObrigacoes: [],
    atividades: []
}
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'GET_GRUPO_OBRIGACOES':
            return { ...state, grupoObrigacoes: action.payload.data.grupoObrigacoes }
        case 'GET_ATIVIDADES_GRUPO_OBRIGACAO':
            return { ...state, atividades: action.payload.data.atividades }
        default:
            return state
    }
}