const INITIAL_STATE = {
    funcoes: [],
    permissoesAcesso: [],
    carregandoFuncoes: false,
    defaultCheckedKeys: [],
    processandoAlteracaoPermissao: false,
    permitirAcessarOutrasEmpresas: false,
    targetKeys: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ESTADO_INICIAL':
            return { ...state, permissoesAcesso: [],  defaultCheckedKeys: [], processandoAlteracaoPermissao: false,
                        permitirAcessarOutrasEmpresas: false}
        case 'SET_CARREGANDO_FUNCOES':
            return {...state, carregandoFuncoes: action.payload }
        case 'GET_FUNCOES':
            return {...state, funcoes: action.payload.data.funcoes }
        case 'GET_PERMISSOES':
            return { ...state, permissoesAcesso: action.payload.data.permissoesAcesso, defaultCheckedKeys: action.payload.data.defaultCheckedKeys }

        case 'PERMITIR_ACESSAR_OUTRAS_EMPRESAS':
            return { ...state, permitirAcessarOutrasEmpresas: action.payload}
            //Processamento de alteração de permissão
        case 'INICIAR_PROCESSAMENTO_ALT_PERMISSAO':
            return { ...state, processandoAlteracaoPermissao: true }
        case 'FINALIZAR_PROCESSAMENTO_ALT_PERMISSAO':
            return { ...state, processandoAlteracaoPermissao: false }

        case 'SET_EMPRESAS_SELECIONADAS':
            return { ...state, targetKeys: action.payload}
        default:
            return state
    }
}