import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Hash from 'react-router-history';
import { change, initialize, SubmissionError } from 'redux-form';
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import {
  CONSULTA_CERTIDAO_FAIL,
  CONSULTA_CERTIDAO_SUCCESS,
  FETCH_MOVIMENTACOES_CERTIDOES_FAIL,
  FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS,
} from '../actions/types';
import tgcw from '../api/tgcw';
import Functions from '../helpers/functions';
import Util from '../helpers/util';
import { isCPF, isMatriz } from '../util/cpfCnpj';
import swalProcessingConfig from '../util/swalProcessingConfig';
import verificaRotaNegada from '../util/verificaRotaNegada';
import { showLoading } from './defaultActions';

const hashHistory = Hash.hashHistory;

export function getCertidoesNegativasDisponiveis(empresaId) {
  const request = axios.get(
    `${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/certidoes-disponiveis/${empresaId}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_CERTIDOES_DISPONIVEIS',
    payload: request,
  };
}

export function shLoading(value) {
  return {
    type: 'SET_LOADING',
    payload: value,
  };
}

export function setListaAnexos(anexos) {
  return {
    type: 'SET_LISTA_ANEXOS',
    payload: anexos,
  };
}

export function setDefaultFileList(files) {
  return {
    type: 'SET_ANEXOS',
    payload: files,
  };
}

export const setListaAnexoString = value => {
  return {
    type: 'SET_CND_ANEXO_STRING',
    payload: value,
  };
};

export const setArquivosUpload = file => dispatch => {
  dispatch([
    change('modalDadosUploadManualForm', 'uploadFile', file.uid),
    { type: 'SET_ANEXO_CERTIDAO', payload: file },
  ]);
};

export const removeArquivoUpload = arquivo => async (dispatch, getState) => {
  dispatch({ type: 'REMOVE_ARQUIVO_UPLOAD', payload: arquivo });

  const { certidaoNegativa } = getState();
  
  const alert = await swal({
    title: 'Tem certeza que deseja excluir o arquivo?',
    icon: 'warning',
    buttons: ['Cancelar', true],
  });

  if(!alert){
    dispatch(
      setListaAnexos(certidaoNegativa.defaultFileList),
      preencheAnexos(certidaoNegativa.defaultFileList)
    )
  }
};

export function getTodasEmpresasAtivasCND() {
  const request = axios.get(
    `${Util.getBaseUrl()}/certidoes-negativas/empresas-ativas-cnd`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_TODAS_EMPRESAS_ATIVAS_CND',
    payload: request,
  };
}

export function setEstadoInicial() {
  return {
    type: 'SET_ESTADO_INICIAL',
  };
}

export function showModalDadosDaCertidao(showModalDadosDaCertidao) {
  return {
    type: 'SHOW_MODAL_DADOSDACERTIDAO',
    payload: showModalDadosDaCertidao,
  };
}

export function showModalUploadManual(showModalUploadManual) {
  return {
    type: 'SHOW_MODAL_UPLOAD_MANUAL',
    payload: showModalUploadManual,
  };
}

export function showModalUploadManualEdit(showModalUploadManualEdit) {
  return {
    type: 'SHOW_MODAL_UPLOAD_MANUAL_EDIT',
    payload: showModalUploadManualEdit,
  };
}

export function getTipoManual(empresaId) {
  const request = axios.get(
    `${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/certidoes-disponiveis-manual/${empresaId}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_TIPO_MANUAL',
    payload: request,
  };
}

export function setNovasCertidoesNegativas(data) {
  return {
    type: 'SET_NOVAS_MOVIMENTACOES_CERTIDOES',
    payload: data,
  };
}
export function setEmpresa(empresa) {
  return {
    type: 'SET_EMPRESA_PARAM_CND',
    payload: empresa,
  };
}

export function setHtmlCaptcha(value) {
  return {
    type: 'GET_HTML_CAPTCHA',
    payload: value,
  };
}

export function setCaptcha(captcha) {
  return {
    type: 'SET_CAPTCHA',
    payload: captcha,
  };
}

export function getDadosDaCertidao() {
  return dispatch => {
    dispatch([showModalDadosDaCertidao(true)]);
  };
}

export function onChangePublicarParaCliente(checked) {
  return {
    type: 'PUBLICAR_PARA_CLIENTE',
    payload: checked,
  };
}
export function getSituacaoCertidoesNegativas() {
  const request = axios.get(
    `${Util.getBaseUrl()}/enumeradores/situacao-certidao`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_SITUACOES_CERTIDOES_NEGATIVAS',
    payload: request,
  };
}

export function getSituacaoCertidoesNegativasManual() {
  const request = axios.get(
    `${Util.getBaseUrl()}/enumeradores/situacao-certidao-manual`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_SITUACOES_CERTIDOES_NEGATIVAS_MANUAL',
    payload: request,
  };
}

export function getStatusCetidoesNegativasEmpresa() {
  const request = axios.get(
    `${Util.getBaseUrl()}/enumeradores/status-certidao-empresa`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_STATUS_CERTIDOES_NEGATIVAS_EMPRESAS',
    payload: request,
  };
}

export function onChangediasAnteciparAtualizacao(dias) {
  return {
    type: 'DIAS_ANTECIPAR_ATUALIZACAO',
    payload: dias,
  };
}

export function initiModalDadosDaCertidao() {
  return dispatch => {
    dispatch([initialize('modalDadosCertidaoForm', null), showModalDadosDaCertidao(false)]);
  };
}

export function initiModalUploadManual() {
  return dispatch => {
    dispatch([initialize('modalDadosUploadManualForm', null), 
    showModalUploadManual(false),
    setListaAnexos([]),
    setDefaultFileList([]),
    setListaAnexoString(''),
  ]);
  };
}

export function initiModalUploadManualEdit(teste) {
  return dispatch => {
    dispatch([
      initialize('editarUploadManualForm', null), 
      showModalUploadManualEdit(false), 
      setListaAnexos([]),
      setDefaultFileList([]),
      setListaAnexoString(''),
    ]);
  };
}

export function getParamentrizacaoCND(dadosGeracaoCND) {
  const tenantId = dadosGeracaoCND.tenant;
  const parametrizacaoCNDId = dadosGeracaoCND.parametrizacaoCND;
  const request = axios.get(
    `${Util.getBaseUrl()}/resolve-captcha/parametrizacao/${tenantId}/${parametrizacaoCNDId}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_DADOS_PARAMETRIZACAO_CND',
    payload: request,
  };
}

export const getSinteseCertidoesNegativasDebitos = () => async (dispatch, getState) => {
  try {
    const {
      form: { buscaForm },
    } = getState();

    dispatch({ type: 'INICIAR_CERTIDOES' });

    let status = null;
    let idEmpresa = null;

    if (buscaForm && buscaForm.values) {
      status = buscaForm.values.situacaoCertidao;
      idEmpresa = buscaForm.values.empresaContabilId;
    }

    const { data } = await tgcw.get(
      `/gestao-certidoes-negativas/certidoes?idEmpresa=${idEmpresa}&status=${status}`,
    );

    dispatch({ type: 'COMPLETADO_CERTIDOES', payload: data });
  } catch (error) {
    dispatch({ type: 'FALHA_CERTIDOES', payload: error });
  }
};

export function getEditarUrl(id) {
  return dispatch => {
    dispatch(hashHistory.push(`/certidoes-negativas/editar/${id}`));
  };
}

export function getCertidoesNegativasById(certidaoNegativaId) {
  return dispatch => {
    axios
      .get(`${Util.getBaseUrl()}/certidoes-negativas/${certidaoNegativaId}`, Util.getHeaders())
      .then(function(resp) {
        if (resp.status === 200) {
          dispatch([
            initialize('certidaoNegativaDebitoForm', resp.data.certidaoNegativaDebito, true),
            hashHistory.push(`/certidoes-negativas/editar/${resp.data.certidaoNegativaDebito.id}`),
          ]);
        } else {
          toastr.error(
            'Certidão Negativa de Débito',
            'Certidao Negativa de Débito não encontrado.',
          );
          dispatch(hashHistory.push('/certidoes-negativas'));
        }
      })
      .catch(err => {
        toastr.error(
          'Certidão Negativa de Débito',
          'Ops, ocorreu um erro ao tentar editar o CND. Tente novamente mais tarde.',
        );
        dispatch(hashHistory.push('/certidoes-negativas'));
      });
  };
}

/* Movimentação Certidões Negativas */

export const getUrlParametrizacaoCNDPorEmpresa = id => {
  hashHistory.push(`/certidoes-negativas/movimentacao-cnd/${id}`, true);
  return { type: 'GET_URL_PARAMETRIZACAO_EMPRESA' };
};

export function initializeFormMovimentacao(id) {
  return dispatch => {
    getEmpresa(id)
      .then(response => {
        const empresa = {
          id: response.empresa.id,
          empresaContabilId: response.id,
          inscricao: response.empresa.inscricao,
          inscricaoMascarado: response.empresa.inscricaoMascarado,
          inscricaoEstadual: response.empresa.inscricaoEstadual,
          razaoSocial: response.empresa.razaoSocial,
        };
        dispatch([setEmpresa(empresa), getMovimentacoesCND(empresa)]);
      })
      .catch(error => {
        if (error.response) {
          if (error.resppose.data) {
            if (_.isArray(error.response.data)) {
              error.response.data.forEach(e =>
                toastr.error('Parametrização de Certidões Negativas', `${e.message}`, {
                  timeOut: 8000,
                }),
              );
            } else {
              toastr.error('Parametrização de Certidões Negativas', `${error.response.data}`, {
                timeOut: 8000,
              });
            }
          } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `Oops... Não foi possível visuzaliar a parametrização desta empresa, \nErro: ${error.response}`,
              { timeOut: 8000 },
            );
          }
        } else {
          toastr.error('Parametrização de Certidões Negativas', `${error.message}`, {
            timeOut: 7000,
          });
        }
      });
  };
}

export function getMovimentacoesCND(empresa) {
  const empresaId = empresa.empresaContabilId;
  return dispatch => {
    dispatch([
      { type: 'INICIAR_CARREGAMENTO_MOV_CND' },
      change(
        'movimentacaoCNDForm',
        'razaoSocial',
        `${empresa.inscricaoMascarado} - ${empresa.razaoSocial}`,
      ),
    ]);

    axios
      .get(
        `${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/movimentacoes/${empresaId}`,
        Util.getHeaders(),
      )
      .then(response => {
        if (response.status === 200) {
          dispatch({ type: 'COMPLETADO_CARREGAMENTO_MOVIMENTACOES_CND', payload: response });
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.resppose.data) {
            if (_.isArray(error.response.data)) {
              error.response.data.forEach(e =>
                toastr.error('Parametrização de Certidões Negativas', `${e.message}`, {
                  timeOut: 8000,
                }),
              );
            } else {
              toastr.error('Parametrização de Certidões Negativas', `${error.response.data}`, {
                timeOut: 8000,
              });
            }
          } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `Opps... Não foi possível carregar as CND's parametrizadas, \nErro: ${error.response}`,
              { timeOut: 8000 },
            );
          }
        } else {
          toastr.error('Parametrização de Certidões Negativas', `${error.message}`, {
            timeOut: 7000,
          });
        }
        dispatch({ type: 'COMPLETADO_CARREGAMENTO_MOV_CND' });
      });
  };
}

function exibirMensagem(certidao, empresa) {
  if (certidao.necessitaInscricaoEstadual && !empresa.inscricaoEstadual)
    swal({
      title: 'Inscrição Estadual',
      text:
        'Essa empresa não possui inscrição estadual cadastrada, e para que essa CND seja gerada, é necessário ter essa informação.',
      icon: 'info',
    });
}

export const editCertidaoNegativa = parametrizacaoCND => async dispatch => {
  const rotaNegada = verificaRotaNegada('/certidoes-negativas/editar');
  if (rotaNegada)
    return toastr.error(
      'Permissões',
      'Ops... Você não tem permissão para executar essa operação. Caso seja necessário fale com o Administrador do sistema!',
      { timeOut: 7000 },
    );

  const certidao = {
    id: parametrizacaoCND.id,
    descricao: parametrizacaoCND.certidaoNegativaDebitoDescricao,
    tipoCertidao: parametrizacaoCND.certidaoNegativaDebitoTipoCertidao,
    setorAtendimentoId: parametrizacaoCND.certidaoNegativaDebitoSetorAtendimentoId,
    enderecoOrigem: parametrizacaoCND.certidaoNegativaDebitoEnderecoOrigem,
    unidadeFederacao: parametrizacaoCND.unidadeFederativa,
    diasAnteciparAtualizacao: parametrizacaoCND.diasAnteciparAtualizacao,
    diasNovaConsultaPositiva: parametrizacaoCND.diasNovaConsultaPositiva,
    publicarParaCliente: parametrizacaoCND.publicarParaCliente,
  };

  dispatch([
    initialize('modalDadosCertidaoForm', certidao, true),
    showModalDadosDaCertidao(true),
    onChangePublicarParaCliente(certidao.publicarParaCliente),
    onChangediasAnteciparAtualizacao(certidao.diasAnteciparAtualizacao),
    selecionaMovimentacao(certidao.id),
  ]);
};

export const uploadManual = parametrizacaoCND => async dispatch => {
 
  const certidao = {
    publicarParaCliente: parametrizacaoCND.certidaoNegativa.publicarParaCliente,
  }

  dispatch([
    initialize('modalDadosUploadManualForm', certidao, true),
    change('modalDadosUploadManualForm', 'uploadFile', null),
    showModalUploadManual(true),
    onChangePublicarParaCliente(certidao.publicarParaCliente)    
   ]);
};

export function salvaUploadManual() {
  return async (dispatch, getstate) => {
    const {form, certidaoNegativa} = getstate();
    
    const data = {
      publicarParaCliente: form.modalDadosUploadManualForm.values.publicarParaCliente,
      setorAtendimentoId: form.modalDadosUploadManualForm.values.setorAtendimentoId,
      situacao: form.editarUploadManualForm.values.situacao,
      certidaoNegativaId: form.modalDadosUploadManualForm.values.descricao,
      EmpresaId: certidaoNegativa.empresa.empresaContabilId,
      DataEmissao: moment(form.editarUploadManualForm.values.dataEmissao, 'DD/MM/YYYY').format(),
      DataValidade: moment(form.editarUploadManualForm.values.dataVencimento, 'DD/MM/YYYY').format(),
      ArquivoPath: certidaoNegativa.anexos
    };

    if(data.situacao &&
      data.certidaoNegativaId &&
      moment(data.DataEmissao).isValid() &&
      moment(data.DataValidade).isValid()
      ){      
        if(data.ArquivoPath.length){
          if(data.ArquivoPath.length === 1){
            if(moment(data.DataEmissao).isAfter(data.DataValidade)){
              toastr.error(
                'Parametrização de Certidões Negativas',
                `A data de emissão deve ser inferior a data de vencimento`,
                { timeOut: 8000 },
              );
            } else {
              dispatch([initiModalUploadManual(), { type: 'INICIAR_CARREGAMENTO_MOV_CND' }]);
              data.ArquivoPath = await sendFiles(data.ArquivoPath);

              axios
              .post(`${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/salva-parametrizacaoCND-manual`, data, Util.getHeadersFunctionName('Certidao Negativa Debito'))
              .then(function(resultado) {
                if (resultado.status == 200) {
                  toastr.success('Dados da Certidão', 'Preferências alteradas com sucesso!');
                  dispatch([initiModalUploadManual(), getMovimentacoesCND(certidaoNegativa.empresa), { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' }, setListaAnexos([])]);
                }
              })
              .catch(error => {
                if (error.response) {
                  if (error.resppose.data) {
                    if (_.isArray(error.response.data)) {
                      error.response.data.forEach(e =>
                        toastr.error('Parametrização de Certidões Negativas', `${e.message}`, {
                          timeOut: 8000,
                        }),
                      );
                    } else {
                      toastr.error('Parametrização de Certidões Negativas', `${error.response.data}`, {
                        timeOut: 8000,
                      });
                    }
                  } else {
                    toastr.error(
                      'Parametrização de Certidões Negativas',
                      `Oops... Não foi possível alterar as preferências, \nErro: ${error.response}`,
                      { timeOut: 8000 },
                    );
                  }
                } else {
                  toastr.error('Parametrização de Certidões Negativas', `${error.message}`, {
                    timeOut: 7000,
                  });
                }
              });
            }  
          } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `Não é permitido anexar mais do que um arquivo`,
                { timeOut: 8000 },
              );
          }          
        } else {
          toastr.error(
            'Parametrização de Certidões Negativas',
            `É necessário anexar o arquivo`,
              { timeOut: 8000 },
            );
        }                    
    } else {
      toastr.error(
        'Parametrização de Certidões Negativas',
        `Os campos marcados com * devem ser preenchidos`,
        { timeOut: 8000 },
      );
    }   
  }  
}

export const editarUploadManual = parametrizacaoCND =>  async dispatch => {
  const rotaNegada = verificaRotaNegada('/certidoes-negativas/editar');

  if (rotaNegada)
    return toastr.error(
      'Permissões',
      'Ops... Você não tem permissão para executar essa operação. Caso seja necessário fale com o Administrador do sistema!',
      { timeOut: 7000 },
    );

  const certidao = {
    id: parametrizacaoCND.id,
    publicarParaCliente: parametrizacaoCND.publicarParaCliente,
    situacao: parametrizacaoCND.situacao,
    dataEmissao: moment(parametrizacaoCND.dataEmissao, 'YYYY/MM/DD').format('L'),
    dataVencimento: moment(parametrizacaoCND.dataValidade, 'YYYY/MM/DD').format('L'),
    anexos: parametrizacaoCND.listaDeAnexos
    
  }

  dispatch([
    initialize('editarUploadManualForm', certidao, true),
    change('editarUploadManualForm', 'uploadFile', certidao.anexos),
    showModalUploadManualEdit(true),
    onChangePublicarParaCliente(certidao.publicarParaCliente),
    selecionaMovimentacao(certidao.id),
    setListaAnexos(certidao.anexos),
    preencheAnexos(certidao.anexos),
   ]);
}

export function atualizarUploadManual () {
  return async (dispatch, getstate) => {
    const {form, certidaoNegativa} = getstate();
         
    const data = {
      id: form.editarUploadManualForm.values.id,
      publicarParaCliente: form.editarUploadManualForm.values.publicarParaCliente,
      situacao: form.editarUploadManualForm.values.situacao,
      EmpresaId: certidaoNegativa.empresa.empresaContabilId,
      DataEmissao: moment(form.editarUploadManualForm.values.dataEmissao, 'DD/MM/YYYY').format(),
      DataValidade: moment(form.editarUploadManualForm.values.dataVencimento, 'DD/MM/YYYY').format(),
      Anexo: certidaoNegativa.anexos ? certidaoNegativa.anexos : ''
    };

    if(data.situacao &&
      data.DataEmissao &&
      data.DataValidade
      ){
        if(data.Anexo.length){
          if(data.Anexo.length === 1){
            if(moment(data.DataEmissao).isAfter(data.DataValidade)){
              toastr.error(
                'Parametrização de Certidões Negativas',
                `A data de emissão deve ser inferior a data de vencimento`,
                { timeOut: 8000 },
              );
            } else {          
              dispatch([initiModalUploadManualEdit(), { type: 'INICIAR_CARREGAMENTO_MOV_CND' }]);
              if(data.Anexo.length){
                data.Anexo = await sendFiles(data.Anexo, certidaoNegativa.empresa);
              }
              axios
                .put(`${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/upload-manual`, data, Util.getHeaders())
                .then(function(resultado) {
                  if (resultado.status == 200) {
                    toastr.success('Dados da Certidão', 'Preferências alteradas com sucesso!');
                    dispatch([initiModalUploadManualEdit(), getMovimentacoesCND(certidaoNegativa.empresa), { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' }, setListaAnexos([])]);
                  } 
                })
                .catch(error => {
                  dispatch([initiModalUploadManualEdit(), getMovimentacoesCND(certidaoNegativa.empresa), { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' }, setListaAnexos([])]);
                  if (error.response) {
                    if (error.resppose.data) {
                      if (_.isArray(error.response.data)) {
                        error.response.data.forEach(e =>
                          toastr.error('Parametrização de Certidões Negativas', `${e.message}`, {
                            timeOut: 8000,
                          }),
                        );
                      } else {
                        toastr.error('Parametrização de Certidões Negativas', `${error.response.data}`, {
                          timeOut: 8000,
                        });
                      }
                    } else {
                      toastr.error(
                        'Parametrização de Certidões Negativas',
                        `Oops... Não foi possível alterar as preferências, \nErro: ${error.response}`,
                        { timeOut: 8000 },
                      );
                    }
                  } else {
                    toastr.error('Parametrização de Certidões Negativas', `${error.message}`, {
                      timeOut: 7000,
                  });
                }
            }).catch(error => {
              console.log("Erro")
              dispatch([initiModalUploadManualEdit(), getMovimentacoesCND(certidaoNegativa.empresa), { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' }, setListaAnexos([])]);
            });
          } 
          } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `Não é permitido anexar mais do que um arquivo`,
                { timeOut: 8000 },
              );     
          }
        } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `É necessário anexar o arquivo`,
                { timeOut: 8000 },
              );
          }   
    } else {
      toastr.error(
        'Parametrização de Certidões Negativas',
        `Os campos marcados com * devem ser preenchidos`,
        { timeOut: 8000 },
      );
    }   
  }  
}

function sendFiles(anexos, empresa) {

  const formData = new FormData();
  
  anexos.filter(anexo => !anexo.url).forEach(file => formData.append('files', file));

  return new Promise((resolve, reject) => {
    axios
      .post(`${Util.getBaseUrl()}/solicitacoes/upload-arquivo`, formData, Util.getHeaders())
      .then(function(response) {
        const meusAnexos = response.data.map(anexo => anexo.replace(/^.*[\\\/]/, '')).join(',');
        resolve(meusAnexos);
      })
      .catch(error => {
        console.log("erro 1")
        reject([initiModalUploadManualEdit(), getMovimentacoesCND(empresa), { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' }, setListaAnexos([])]);
        if (error.response.status === 400) {
          let cont = 0;
          const msg = 'Anexo: ';
          let meusAnexos = '';
          error.response.data.forEach(anexo => {
            cont++;
            meusAnexos += `\n ${msg} ${cont} : ${anexo} \n`;
          });

          const errors = meusAnexos;

          reject(errors);
        } else {
          reject(errors);
        }
      });
  }).catch(error => {
    console.log("erro 2")
  });
}

export const preencheAnexos = listaAnexos => dispatch => {
  const defaultFileList = listaAnexos.map((url, index) => ({
    uid: index++,
    name: url.substring(url.lastIndexOf('/') + 1),
    status: 'done',
    response: 'Server sucess 200',
    url,
  }));

  dispatch([setDefaultFileList(defaultFileList), setListaAnexos(defaultFileList)]);
};

export const selecionaMovimentacao = movimentacaoId => {
  return {
    type: 'SELECIONA_MOV_CND',
    payload: movimentacaoId,
  };
};

function getEmpresa(empresaId) {
  return new Promise((resolve, reject) => {
    tgcw
      .get(`/empresas/empresa-base/${empresaId}`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Editar CND para publicar e para quantos dias é para antecipar
 * @param {string} id certidaNegativa.id
 * @param {boolean} publicar certidaoNegativa.publicarParaCliente
 * @param {number} antecipar certidaoNegativa.diasAnteciparAtualizacao
 */
export function editCnd() {
  return (dispatch, getstate) => {
    const { form, certidaoNegativa } = getstate();
    const data = {
      id: form.modalDadosCertidaoForm.values.id,
      publicarParaCliente: form.modalDadosCertidaoForm.values.publicarParaCliente,
      diasAnteciparAtualizacao: form.modalDadosCertidaoForm.values.diasAnteciparAtualizacao,
      diasNovaConsultaPositiva: form.modalDadosCertidaoForm.values.diasNovaConsultaPositiva,
      setorAtendimentoId: form.modalDadosCertidaoForm.values.setorAtendimentoId,
    };

    axios
      .put(`${Util.getBaseUrl()}/parametrizacao-certidoes-negativas`, data, Util.getHeaders())
      .then(function(resultado) {
        if (resultado.status == 200) {
          toastr.success('Dados da Certidão', 'Preferências alteradas com sucesso!');
          dispatch([initiModalDadosDaCertidao(), getMovimentacoesCND(certidaoNegativa.empresa)]);
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.resppose.data) {
            if (_.isArray(error.response.data)) {
              error.response.data.forEach(e =>
                toastr.error('Parametrização de Certidões Negativas', `${e.message}`, {
                  timeOut: 8000,
                }),
              );
            } else {
              toastr.error('Parametrização de Certidões Negativas', `${error.response.data}`, {
                timeOut: 8000,
              });
            }
          } else {
            toastr.error(
              'Parametrização de Certidões Negativas',
              `Oops... Não foi possível alterar as preferências, \nErro: ${error.response}`,
              { timeOut: 8000 },
            );
          }
        } else {
          toastr.error('Parametrização de Certidões Negativas', `${error.message}`, {
            timeOut: 7000,
          });
        }
      });
  };
}

export const adicionarCertidaoNegativa = dataSource => dispatch => {
  const rotaNegada = verificaRotaNegada('/certidoes-negativas/adicionar');
  if (rotaNegada)
    return toastr.error(
      'Permissões',
      'Ops... Você não tem permissão para executar essa operação. Caso seja necessário fale com o Administrador do sistema!',
      { timeOut: 7000 },
    );

  const obj = Functions.findObjectByKey(dataSource, 'id');

  if (!obj) {
    const target = {
      id: null,
      key: dataSource.length.toString(),
      descricao: '',
      publicarParaCliente: '',
      diasAnteciparAtualizacao: '',
      enderecoOrigem: '',
      tipoCertidao: '',
      unidadeFederacao: '',
      setorAtendimentoId: '',
    };

    const newData = [...dataSource, target];

    target.editable = true;
    dispatch(setNovasCertidoesNegativas(newData));
  } else {
    toastr.warning(
      'Certidão Negativa Débito',
      'Por favor finalize o cadastro para adicionar um novo registro.',
    );
  }
};

function alertCertidaoReceitaPJ(mensagem) {
  Swal.fire('Atenção!', mensagem, 'warning');
}

export const salvaParametrizacaoCND = (certidao, empresa) => dispatch => {
  const certidaoNegativa = {
    certidaoNegativaId: certidao.id,
    empresaId: empresa.empresaContabilId,
  };

  if (certidao.descricao === 'RECEITA FEDERAL PJ') {
    if (isCPF(empresa.inscricao)) {
      alertCertidaoReceitaPJ('CND deve ser utilizada apenas para inscrições CNPJ!');
      return;
    } else if (!isMatriz(empresa.inscricaoMascarado)) {
      alertCertidaoReceitaPJ('CND dever ser emitida pela Matriz!');
      return;
    }
  }

  dispatch({ type: 'INICIAR_CARREGAMENTO_MOV_CND' });

  axios
    .post(
      `${Util.getBaseUrl()}/parametrizacao-certidoes-negativas/salva-parametrizacaoCND`,
      certidaoNegativa,
      Util.getHeadersFunctionName('Certidao Negativa Debito'),
    )
    .then(resp => {
      toastr.success('Parametrização', 'Inclusão realizada com sucesso!');
      dispatch([
        exibirMensagem(certidao, empresa),
        getMovimentacoesCND(empresa),
        { type: 'COMPLETADO_CARREGAMENTO_MOV_CND' },
      ]);
    })
    .catch(errs => {
      if (_.isArray(errs.response)) {
        let errMessage = '';
        errs.response.data.map(function(err) {
          errMessage += `${err.message}\n`;
        });

        toastr.error('Certidão Negativa Débito', `${errMessage}`);
        dispatch({ type: 'COMPLETADO_CARREGAMENTO_MOV_CND' });
      } else {
        toastr.error('Certidão Negativa Débito', 'Oops... Não foi possível salvar a obrigação.');
        dispatch({ type: 'COMPLETADO_CARREGAMENTO_MOV_CND' });
      }
    });
};

export const deleteParametrizacaoCND = movimentacao => async (dispatch, getState) => {
  const { certidaoNegativa } = getState();
  const alert = await swal({
    title: 'Deseja realmente retirar essa Certidão da parametrização dessa Empresa?',
    icon: 'warning',
    buttons: ['Cancelar', true],
  });

  if (!alert) return;

  try {
    await tgcw.delete(
      `/parametrizacao-certidoes-negativas/${movimentacao.id}`,
      Util.getHeadersFunctionName('Certidoes negativas debito'),
    );
    toastr.success('Sucesso!', 'A operação foi realizada com sucesso!');
    dispatch(getMovimentacoesCND(certidaoNegativa.empresa));
  } catch (err) {
    if (err.message !== 'Access Denied')
      return toastr.error('Parametrização', 'Oops... Não foi possível realizar a operação.');

    toastr.error(
      'Permissão',
      'Ops... Você não tem permissão para executar essa operação. Caso seja necessário fale com o Administrador do sistema!',
      { timeOut: 6000 },
    );
  }
};

export function setDadosParaGeracaoCND(params) {
  return {
    type: 'SET_DADOS_PARA_GERACAO_CND',
    payload: params,
  };
}

export function enviarNotificacaoCND(parametrizacaoCND) {
  return (dispatch, getState) => {
    const { usuarioLogado } = getState();
    axios
      .get(
        `${Util.getBaseUrl()}/certidoes-negativas/envia-notificacao-cnd/${parametrizacaoCND.id}`,
        Util.getHeaders(),
      )
      .then(resp => {
        swal('Operação realizada com sucesso.', { icon: 'success' });
        dispatch(resp.data);
      });
  };
}

export const sendCaptcha = (
  dadosGeracaoCND,
  empresaInscricao,
  captcha,
  empresaInscricaoEstadual,
) => async dispatch => {
  const tenantId = dadosGeracaoCND.tenant;
  const parametrizacaoCNDId = dadosGeracaoCND.parametrizacaoCND;
  empresaInscricaoEstadual = empresaInscricaoEstadual || null;
  dispatch(shLoading(true));
  dispatch({ type: 'INCIANDO_GERACAO_CND' });
  try {
    await axios.get(
      `${Util.getBaseUrl()}/resolve-captcha/${tenantId}/${parametrizacaoCNDId}/${captcha}/${empresaInscricao}/${empresaInscricaoEstadual}`,
    );
    swal({
      title: 'Operação realizada com sucesso.',
      text:
        'A CND foi gerada com sucesso, Para visualizar o arquivo, acesse o Portal TRON SMART, e acione o menu Gestão CND.',
      icon: 'success',
    });
    dispatch([{ type: 'FINALIZANDO_GERACAO_CND' }, shLoading(false)]);
  } catch (error) {
    dispatch([
      { type: 'FINALIZANDO_GERACAO_CND' },
      recarregarSessao(dadosGeracaoCND),
      shLoading(false),
    ]);
    if (error.response.status === 400)
      throw new SubmissionError({ captcha: 'O captcha informado é inválido.' });

    swal({
      title: 'Oops...',
      text: 'O sistema não se comportou conforme o esperado, por favor tente novamente mais tarde.',
      icon: 'error',
    });
  }
};

export function recarregarSessao(dadosGeracaoCND) {
  const tenantId = dadosGeracaoCND.tenant;
  const parametrizacaoCNDId = dadosGeracaoCND.parametrizacaoCND;
  return dispatch => {
    dispatch(showLoading(true));
    axios
      .get(
        `${Util.getBaseUrl()}/resolve-captcha/recarrega-sessao/${tenantId}/${parametrizacaoCNDId}`,
        Util.getHeaders(),
      )
      .then(response => {
        dispatch([getHtmlCaptcha(tenantId, parametrizacaoCNDId), showLoading(false)]);
      })
      .catch(error => {
        toastr.error(
          `Opps... Ocorreu um erro ao tentar recarregar o captcha, erro: ${error.message}`,
        );
        dispatch(showLoading(false));
      });
  };
}

export function getHtmlCaptcha(tenantId, parametrizacaoCNDId) {
  return dispatch => {
    dispatch({ type: 'INICIA_CARGA_CAPCHA' });
    axios
      .get(`${Util.getBaseUrl()}/resolve-captcha/${tenantId}/${parametrizacaoCNDId}`)
      .then(response => {
        if (response.status === 200) dispatch(setHtmlCaptcha(response));
      });
  };
}

export const fetchCertidaoNegativa = ({ id, empresaId }) => async dispatch => {
  const { processing } = swalProcessingConfig('Buscando a certidão negativa de débito, aguarde...');

  try {
    Swal.fire(processing);
    await tgcw.put(`/certidoes-negativas/${id}/consultar`);
    dispatch(fetchMovimentacoesCertidoes(empresaId));
    dispatch({ type: CONSULTA_CERTIDAO_SUCCESS });
  } catch (err) {
    if ('response' in err) toastr.error('Ops...', err.response.data.mensagem);
    else toastr.error('Ops...', 'Aconteceu um erro inesperado!');

    dispatch({ type: CONSULTA_CERTIDAO_FAIL });
  } finally {
    Swal.close();
  }
};

export const fetchMovimentacoesCertidoes = empresaContabilId => async dispatch => {
  try {
    const { data } = await tgcw.get(
      `/parametrizacao-certidoes-negativas/movimentacoes/${empresaContabilId}`,
    );
    dispatch({ type: FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS, payload: data.movimentacoesCND });
  } catch (err) {
    dispatch({ type: FETCH_MOVIMENTACOES_CERTIDOES_FAIL });
  }
};
