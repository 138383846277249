import axios from 'axios';

import configureHeaders from '../util/configureHeaders';
import sentinelaFront from '../util/sentinelaFront';

const baseURL = process.env.REACT_APP_SENTINELA_API_BASE_URL;

const sentinela = () => {
  const defaultOptions = {
    baseURL,
    headers: {
      Accept: 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => configureHeaders(config));

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) {
        sessionStorage.clear();

        const { origin } = window.location;
        const redirectURL = `redirectURL=${origin}&toLocation=/`;

        return window.location.assign(
          `${sentinelaFront[process.env.NODE_ENV]}/#/login?${redirectURL}&fromApplication=1`,
        );
      }

      return Promise.reject(err);
    },
  );

  return instance;
};

export default sentinela();
