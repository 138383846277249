import { Alert } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Header from "../../theme/header";

class NotFound extends React.Component {
  render() {
    if(this.props.rotas.menus.length === 0) return <div>Loading...</div>;

    const { tipo } = this.props.usuario.pessoaLogada;
    return (
      <div>
        <Header title="Página não encontrada">
          {tipo !== "Cliente" ? (
            <Link to="/home" className="btn btn-primary btn-sm">
              Dashboard
            </Link>
          ) : (
            <Link to="/solicitacoes" className="btn btn-primary btn-sm">
              Solicitações
            </Link>
          )}
        </Header>
        <div className="wrapper wrapper-content">
          <Alert
            message="Página não encontrada"
            description="Oops. A página solicitada não foi encontrada. Por favor entre em contato com o administrador."
            type="error"
            showIcon
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario,
  rotas: state.rota
});

export default connect(mapStateToProps)(NotFound);
