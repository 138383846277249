import _ from 'lodash';

import {
  FETCH_EMPRESA_OBRIGACOES_SUCCESS,
  FETCH_EMPRESA_PARAMETROS_SUCCESS,
  FETCH_EMPRESAS_OBRIGACOES,
  FETCH_NUMERO_VERSOES_OBRIGACAO_SUCCESS,
  FETCH_OBRIGACAO_EMPRESA_SUCCESS,
  FETCH_OBRIGACAO_TAREFAS_SUCESSO,
  FILTRAR_EMPRESAS,
  FT_ALTERA_STATUS_FAVORITO_SUCCESS,
  LIMPAR_EMPRESA_OBRIGACOES,
  PROCESSAR_OBRIGACOES_SUCCESS,
  SET_COPIA_ITENS_OBRIGACAO_EMPRESA,
  SHOW_TAREFAS_CONCLUIDAS,
  UPDATE_EMPRESA_OBRIGACAO_STATUS,
  UPDATE_MOVIMENTACAO_OBRIGACAO_SUCCESS,
  UPDATE_RESPONSAVEIS_MOVIMENTACAO_SUCCESS,
  UPDATE_TAREFA_SUCCESS,
  UPDATE_TODAS_TAREFAS_SUCCESS,
  SET_PARAMETRO_SELECIONADO,
} from '../actions/types';

const INITIAL_STATE = {
  obrigacao: {
    descricao: '',
    tipo: 0,
    setor: 0,
    criticidade: 0,
    itens: [
      {
        descricao: '',
        prazo: '',
        hora: '',
        responsavel: 0,
      },
    ],
  },
  escritoriosEmpresas: [],
  empresas: [],
  empresasFiltrado: [],
  parametros: [], //Atual
  parametroVigente: '',
  obrigacoes: [],
  showButtonApresentacao: false,
  showGridParametro: false,
  empresaId: null,
  parametroSelecionado: null,
  //MODAL OBRIGAÇÃO
  obrigacaoSelecionada: {},
  showGridObrigacao: false,
  showModal: false,
  atividades: [],
  atividadesDisponiveis: [],
  tiposAtividade: [],
  criticidades: [],
  periodicidades: [],
  tiposVencimento: [],
  tiposTratativasVencimento: [],
  tiposResponsavel: [],
  setoresAtendimentos: [],
  prazoTotal: 0,
  horaTotal: '',
  exibeDefinicoesDeVencimento: true,
  exibeAmbitoObrigacaoTributaria: true,
  periodosVencimentos: [],
  campoVencimento: {},
  disabledPeriodoVencimento: false,
  disabledTipoTrativaVencimento: true,
  desabilitaCriticidade: false,
  showGridTarefa: false,
  itens: [],
  atividade: '',
  dataServidor: '',
  paramVigente: '',
  rowSelecionada: null,
  carregandoInformacoesParametros: false,
  carregandoInformacoesObrigacoes: false,
  ambitosObrigacaoAcessoria: [],
  ambitoObrigacao: 0,
  desabilitaForm: false,
  showModalCopiaObrigacao: false,
  qtdEmpresasObrigacoes: 0,
  empresasObrigacoes: [],
  showTarefasConcluidas: false,
  obrigacaoTarefas: [],
  selectedRowIds: [],
  empresasComParametros: [],
  versoesObrigacao: [],
  itensObrigacaoOriginal: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_ESCRITORIOS_EMPRESAS':
      return { ...state, escritoriosEmpresas: action.payload.data.escritoriosEmpresas };
    case 'GET_EMPRESAS':
      return {
        ...state,
        empresas: action.payload.data.empresas,
        qtdEmpresasObrigacoes: action.payload.data.empresastotal,
      };
    case 'SET_PARAMETROS':
      return {
        ...state,
        parametros: action.payload.data.empresasParametros,
        showGridParametro: action.payload.showGrid,
      };
    case 'SET_PARAMETRO_VIGENTE':
      return { ...state, parametroVigente: action.payload.data };
    case 'SET_OBRIGACOES':
      return {
        ...state,
        obrigacoes: action.payload.data.empresasObrigacoes,
        showGridObrigacao: action.payload.showGrid,
      };
    case 'SHOW_GRID_PARAMETRO':
      return { ...state, showGridParametro: action.payload };
    case 'SHOW_GRID_OBRIGACAO':
      return { ...state, showGridObrigacao: action.payload };
    case 'SET_NOVOS_PARAMETROS':
      return { ...state, parametros: action.payload };
    case 'SET_EMPRESA_ID':
      return { ...state, empresaId: action.payload };
    case 'SET_EMPRESAS_OBRIGACOES':
      return {
        ...state,
        empresas: action.payload.empresas,
        empresasFiltrado: action.payload.empresas,
        qtdEmpresasObrigacoes: action.payload.total,
      };
    case 'SET_INITIAL_STATE':
      return {
        ...state,
        parametros: [],
        obrigacoes: [],
        showButtonApresentacao: false,
        showGridParametro: false,
        showGridObrigacao: false,
        empresaId: null,
      };
    case 'SHOW_BUTTON_APRESENTACAO':
      return { ...state, showButtonApresentacao: action.payload };
    case SET_PARAMETRO_SELECIONADO:
      return { ...state, parametroSelecionado: action.payload };
    case 'SET_OBRIGACAO_SELECIONADA':
      return { ...state, obrigacaoSelecionada: action.payload };
    case 'SET_NOVAS_OBRIGACOES':
      return { ...state, obrigacoes: action.payload };
    case 'GET_ATIVIDADES':
      return { ...state, atividades: action.payload.data.atividades };
    case 'GET_ATIVIDADE':
      return { ...state, atividade: action.payload.data };
    case 'GET_TIPOS_ATIVIDADE':
      return { ...state, tiposAtividade: action.payload.data };
    case 'GET_CRITICIDADES':
      return { ...state, criticidades: action.payload.data };
    case 'GET_PERIODICIDADES':
      return { ...state, periodicidades: action.payload.data };
    case 'GET_TIPOS_VENCIMENTO':
      return { ...state, tiposVencimento: action.payload.data };
    case 'GET_TIPOS_TRATATIVAS_VENCIMENTO':
      return { ...state, tiposTratativasVencimento: action.payload.data };
    case 'GET_TIPOS_RESPONSAVEL':
      return { ...state, tiposResponsavel: action.payload.data };
    case 'GET_SETORES_ATENDIMENTOS':
      return { ...state, setoresAtendimentos: action.payload.data.setoresAtendimentos };
    case 'GET_DEFINICOES_DE_VENCIMENTO':
      return {
        ...state,
        exibeDefinicoesDeVencimento: action.payload.exibeDefinicoesDeVencimento,
        desabilitaCriticidade: action.payload.desabilitaCriticidade,
        exibeAmbitoObrigacaoTributaria: action.payload.exibeAmbitoObrigacaoTributaria,
      };
    case 'GET_PERIODOS_VENCIMENTOS':
      return { ...state, periodosVencimentos: action.payload.data };
    case 'SET_CAMPO_VENCIMENTO':
      return {
        ...state,
        campoVencimento: action.payload.campo,
        disabledPeriodoVencimento: action.payload.disabledPeriodoVencimento,
        disabledTipoTrativaVencimento: action.payload.disabledTipoTrativaVencimento,
      };
    case 'SHOW_GRID_TAREFA':
      return { ...state, showGridTarefa: action.payload };
    case 'SHOW_MODAL':
      return { ...state, showModal: action.payload };
    case 'SET_TAREFAS':
      return { ...state, itens: action.payload };
    case 'GET_ATIVIDADES_DISPONIVEIS':
      return { ...state, atividadesDisponiveis: action.payload.data.atividadesDisponiveis };
    case 'GET_PARAM_VIGENTE':
      return { ...state, paramVigente: action.payload.data.parametroVigente };
    case 'ROW_PARAMETRO_VIGENTE_SELECIONADO':
      return { ...state, rowSelecionada: action.payload };

    // CARREGAMENTO DE PARAMETROS
    case 'CARREGANDO_INFORMACOES_PARAMETROS':
      return { ...state, carregandoInformacoesParametros: true };
    case 'COMPLETADO_CARREGAMENTO_PARAMETROS':
      return { ...state, carregandoInformacoesParametros: false };

    // CARREGAMENTO DE OBRIGAÇÕES
    case 'CARREGANDO_INFORMACOES_OBRIGACOES':
      return { ...state, carregandoInformacoesObrigacoes: true };
    case 'COMPLETADO_CARREGAMENTO_OBRIGACOES':
      return { ...state, carregandoInformacoesObrigacoes: false };

    case 'GET_AMBITOS_OBRIGACAO_ACESSORIA_EMP_OBRIGACAO':
      return { ...state, ambitosObrigacaoAcessoria: action.payload.data };
    case 'SET_DISABLED_UNIDADE_FEDERATIVA_EMP_OBRIGACAO':
      return { ...state, desabilitaUnidadeFederativa: action.payload };
    case 'SET_CAMPO_AMBITO_EMP_OBRIGACAO':
      return { ...state, ambitoObrigacao: action.payload };
    case 'SET_DESABILITA_FORM':
      return { ...state, desabilitaForm: action.payload };
    case 'SHOW_MODAL_COPIA_OBRIGACAO':
      return { ...state, showModalCopiaObrigacao: action.payload };
    case 'GET_EMPRESAS_PARA_COPIA':
      return { ...state, empresasComParametros: action.payload };

    // FILTRAGEM DE EMRPESAS - OBRIGAÇÕES MAPEADAS E NÃO MAPEADAS
    case 'GET_EMPRESAS_OBRIGACOES_MAPEADAS':
      return { ...state, empresas: action.payload };
    case 'GET_EMPRESAS_OBRIGACOES_NAO_MAPEADAS':
      return { ...state, empresas: action.payload };
    case 'SET_QTD_EMPRESAS_MAPEADAS':
      return { ...state, qtdEmpresasMapeadas: action.payload };
    case 'SET_QTD_EMPRESAS_NAO_MAPEADAS':
      return { ...state, qtdEmpresasNaoMapeadas: action.payload };
    case 'SET_EMPRESAS_OBRIGACAO':
    case UPDATE_EMPRESA_OBRIGACAO_STATUS:
    case FILTRAR_EMPRESAS:
      return { ...state, empresasFiltrado: action.payload };
    case 'GET_TODAS_EMPRESAS':
      return { ...state, empresas: action.payload, empresasFiltrado: action.payload };
    case FT_ALTERA_STATUS_FAVORITO_SUCCESS:
    case UPDATE_RESPONSAVEIS_MOVIMENTACAO_SUCCESS:
    case FETCH_EMPRESAS_OBRIGACOES:
      return { ...state, empresasObrigacoes: action.payload };
    case SHOW_TAREFAS_CONCLUIDAS:
      return { ...state, showTarefasConcluidas: action.payload };
    case FETCH_EMPRESA_PARAMETROS_SUCCESS:
      return { ...state, parametros: action.payload };
    case FETCH_EMPRESA_OBRIGACOES_SUCCESS:
    case UPDATE_MOVIMENTACAO_OBRIGACAO_SUCCESS:
    case PROCESSAR_OBRIGACOES_SUCCESS:
      return { ...state, obrigacoes: action.payload };
    case UPDATE_TODAS_TAREFAS_SUCCESS:
    case UPDATE_TAREFA_SUCCESS:
    case FETCH_OBRIGACAO_TAREFAS_SUCESSO:
      return {
        ...state,
        obrigacaoTarefas: action.payload,
        selectedRowIds: _.keys(_.pickBy(action.payload, { concluido: true }))
          .sort()
          .reduce((o, k) => (o[k] = true, o), {})
      };

    case FETCH_NUMERO_VERSOES_OBRIGACAO_SUCCESS:
      return { ...state, versoesObrigacao: action.payload };
    case FETCH_OBRIGACAO_EMPRESA_SUCCESS:
      return { ...state, obrigacaoSelecionada: action.payload };
    case SET_COPIA_ITENS_OBRIGACAO_EMPRESA:
      return { ...state, itensObrigacaoOriginal: _.keyBy(action.payload, 'rowId') };
    case LIMPAR_EMPRESA_OBRIGACOES:
      return { ...state, obrigacoes: [] };
    default:
      return state;
  }
}
