import axios from 'axios';

import configureHeaders from '../util/configureHeaders';
import notificacaoURLs from '../util/notificacaoURLs';

const baseURL = process.env.REACT_APP_API_NOTIFICACAO_URL;

const notificacao = () => {
  const defaultOptions = {
    baseURL,
    headers: {
      Accept: 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => configureHeaders(config));

  return instance;
};

export default notificacao();
