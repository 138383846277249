import './styles/CaptchaCND.css';

import { Button, Row } from 'antd';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, isPristine, isInvalid } from 'redux-form';

import {
    getHtmlCaptcha,
    getParamentrizacaoCND,
    recarregarSessao,
    sendCaptcha,
    setCaptcha,
    setDadosParaGeracaoCND,
} from '../../actions/certidaoNegativaDebitoActions';
import { AppInput } from '../../theme/inputs';
import { required } from '../../util/validations';
import CaptchaPortal from './CaptchaPortal';


//Pacotes do React
class CaptchaCnd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            captcha: '',
            errors: ''
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    // obtenhaHtmlCaptcha(tenantId, parametrizacaoCNDId){
    //     axios.get(`${Util.getBaseUrl()}/resolve-captcha/${tenantId}/${parametrizacaoCNDId}`).then(res => {
    //         this.setState({ htmlCaptcha: res.data.htmlCaptcha })
    //     })
    // }

    componentDidMount() {
        const { tenant, cndId: parametrizacaoCND } = qs.parse(this.props.location.search);

        if (!tenant || !parametrizacaoCND) return;

        this.props.setDadosParaGeracaoCND({ tenant, parametrizacaoCND});
        this.props.getParamentrizacaoCND({ tenant, parametrizacaoCND});
        this.props.getHtmlCaptcha(tenant, parametrizacaoCND)
    }

    htmlCaptcha() {
        return { __html: this.props.certidaoNegativa.htmlCaptcha }
    }

    renderCaptcha() {
        return (
            <center>
                <Row>
                    <center>
                        <div className="col-sm-12" style={{ width: '100%' }}>
                            <center>
                                <div dangerouslySetInnerHTML={this.htmlCaptcha()} />
                            </center>
                        </div>

                        <div className="col-sm-12" style={{ width: '100%' }}>
                            <center>
                                <Button 
                                    style={{ marginTop: 10 }}
                                    type="primary"
                                    loading={this.props.default.showLoading}
                                    onClick={() => this.props.recarregarSessao(this.props.certidaoNegativa.dadosGeracaoCND)}
                                    className="btn btn-primary" 
                                >
                                    <i className="fa fa-refresh" /> Recarregar Captcha
                                </Button>
                            </center>
                        </div>
                    </center>
                </Row>
            </center>
        )
    }

    onSubmit(values) {
        const {
            certidaoNegativa: {
                dadosGeracaoCND,
                dadosParametrizacaoCND : {
                    empresaEmpresaInscricao,
                    empresaEmpresaInscricaoEstadual
                }
            }
        } = this.props;
      
        this.props.sendCaptcha(dadosGeracaoCND, empresaEmpresaInscricao, values.captcha, empresaEmpresaInscricaoEstadual)
            .catch(({ errors }) => {
                this.setState({ errors });
            });          
    }

    renderCaptchaCND() {
        const { handleSubmit } = this.props;
        return (
            <div className="row">
                <div className="col-md-6"></div>
                <div className="col-md-3">
                    <div className="animated fadeInDown" style={{ marginTop: 100 }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ibox-content" style={{ border: '1px solid #eee' }}>
                                    <center><img src="img/tgc.png" /></center>
                                    {/* <Spin tip="Aguarde estamos processando sua CND ..." spinning={this.props.certidaoNegativa.gerandoCertidao}> */}
                                    <form 
                                        onSubmit={handleSubmit(this.onSubmit)}
                                        className="m-t"   
                                    >
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <h3><center>Empresa: {this.props.certidaoNegativa.dadosParametrizacaoCND.empresaEmpresaRazaoSocial ? this.props.certidaoNegativa.dadosParametrizacaoCND.empresaEmpresaRazaoSocial : '(Nome da empresa)'}</center></h3>
                                                    <h3><center>CND: {this.props.certidaoNegativa.dadosParametrizacaoCND.certidaoNegativaDebitoDescricao ? this.props.certidaoNegativa.dadosParametrizacaoCND.certidaoNegativaDebitoDescricao : '(descrição)'}</center></h3>
                                                    <h3 style={{ fontWeight: 'bold', marginTop: 30 }}><center>Autorize aqui a emissão da Certidão</center></h3>
                                                    {/* <Spin tip="Carregando captcha..." spinning={this.props.certidaoNegativa.carregandoCapcha}> */}
                                                    {this.renderCaptcha()}
                                                    {/* </Spin> */}
                                                    <div style={{ marginTop: 10 }}>
                                                        {/* <Input
                                                            value={this.props.certidaoNegativa.captcha}
                                                            
                                                        /> */}
                                                        <Field 
                                                            name="captcha"
                                                            component={AppInput}
                                                            validate={required}
                                                            onFocus={() => this.setState({ errors: ''})}
                                                            
                                                        />
                                                        <small className="help-block">
                                                            {(this.state.errors !== '') && <span className="text-danger">{this.state.errors.captcha}</span>}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            style={{ marginRight: 4 }}
                                            type="primary"
                                            loading={this.props.certidaoNegativa.shLoading}
                                            //onClick={() => this.props.sendCaptcha(this.props.certidaoNegativa.dadosGeracaoCND, this.props.certidaoNegativa.dadosParametrizacaoCND.empresaEmpresaInscricao, this.props.certidaoNegativa.captcha, this.props.certidaoNegativa.dadosParametrizacaoCND.empresaEmpresaInscricaoEstadual)} className="btn btn-primary block full-width m-b">
                                            htmlType="submit"
                                            block
                                        >
                                            Enviar</Button>
                                    </form>

                                    <p className="m-t" style={{ marginTop: 20 }}>
                                        <small>Tron {new Date().getFullYear()}.</small>
                                    </p>
                                    {/* </Spin> */}
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        );
    }

    render() {
        const { tenant, cndId: parametrizacaoCND } = qs.parse(this.props.location.search);

        if (!tenant || !parametrizacaoCND) return <Redirect to={{ pathname: "/", state: { from: this.props.location } }} />;
        return (
            <CaptchaPortal>
                <div className="captcha-style">
                    {this.renderCaptchaCND()}
                </div>
            </CaptchaPortal>
        );
    }
}

const mapStateToProps = state => ({
    certidaoNegativa: state.certidaoNegativa,
    default: state.default,
    form: state.form,
    invalid: isInvalid('captchaForm')(state),
    pristine: isPristine('captchaForm')(state),
})


const mapDispatchToProps = dispatch => bindActionCreators({ 
    setDadosParaGeracaoCND,
    sendCaptcha,
    getParamentrizacaoCND,
    setCaptcha,
    recarregarSessao,
    getHtmlCaptcha
}, dispatch);

export default reduxForm({form: 'captchaForm'})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CaptchaCnd)
);