import { lazy } from "react";

const Dashboard = lazy(() => import("../pages/dashboard/index"));
const EmpresaIndex = lazy(() => import("../pages/empresa"));
const EmpresaAdicionar = lazy(() => import("../pages/empresa/adicionar"));
const EmpresaEditar = lazy(() => import("../pages/empresa/editar"));
const EmpresaObrigacaoEditar = lazy(() => import("../pages/empresaObrigacao2"));
const EmpresaObrigacaoIndex = lazy(() => import("../pages/empresaObrigacao2/indexObrigacoes"));
const EscritorioIndex = lazy(() => import("../pages/escritorio"));
const AtividadeIndex = lazy(() => import("../pages/atividade"));
const AtividadeAdicionar = lazy(() => import("../pages/atividade/adicionar"));
const AtividadeEditar = lazy(() => import("../pages/atividade/editar"));
const PessoaEmpresaIndex = lazy(() => import("../pages/pessoaEmpresa"));
const PessoaEmpresaAdicionar = lazy(() => import("../pages/pessoaEmpresa/adicionar"));
const PessoaEmpresaEditar = lazy(() => import("../pages/pessoaEmpresa/editar"));
const PessoaEscritorioIndex = lazy(() => import("../pages/pessoaEscritorio"));
const PessoaEscritorioAdicionar = lazy(() => import("../pages/pessoaEscritorio/adicionar"));
const PessoaEscritorioEditar = lazy(() => import("../pages/pessoaEscritorio/editar"));
const SetorAtendimentoIndex = lazy(() => import("../pages/setorAtendimento"));
const SetorAtendimentoAdicionar = lazy(() => import("../pages/setorAtendimento/adicionar"));
const SetorAtendimentoEditar = lazy(() => import("../pages/setorAtendimento/editar"));
const SolicitacaoIndex = lazy(() => import("../pages/solicitacao"));
const SolicitacaoAdicionar = lazy(() => import("../pages/solicitacao/adicionar"));
const SolicitacaoEditar = lazy(() => import("../pages/solicitacao/editar"));
const PesquisaIndex = lazy(() => import("../pages/pesquisa"));
const PermissaoAcessoPessoaEscIndex = lazy(() => import("../pages/permissaoAcessoPessoaEscritorio"));
const PermissaoAcessoPessoaEmpIndex = lazy(() => import("../pages/permissaoAcessoPessoaEmpresa"));
const AcessoNaoAutorizadoIndex = lazy(() => import("../pages/naoAutorizado"));
const EditInfoPessoalPessoaEscritorio = lazy(() => import("../pages/login/informacaoPessoal/editInfoPessoaEscritorio"));
const EditInfoPessoalPessoaEmpresa = lazy(() => import("../pages/login/informacaoPessoal/editInfoPessoaEmpresa"));
const GrupoObrigacaoEditar = lazy(() => import("../pages/grupoObrigacao/editar"));
const GrupoObrigacaoAdicionar = lazy(() => import("../pages/grupoObrigacao/adicionar"));
const GrupoObrigacaoIndex = lazy(() => import("../pages/grupoObrigacao"));
const GestaoDocumentosPublicados = lazy(() => import("../pages/gestaoDocumento/documentoIndex"));
const GestaoDocumentosIndex = lazy(() => import("../pages/gestaoDocumento"));
const PainelGestao = lazy(() => import("../pages/gestao/painelGestao"));
const MovimentacaoCNDIndex = lazy(() => import("../pages/certidaoNegativaDebito/movimentacaoCNDDebito/Index"));
const CaptchaCnd = lazy(() => import("../pages/certidaoNegativaDebito/captchaCnd"));
const CertidaoNegativaDebitoIndex = lazy(() => import("../pages/certidaoNegativaDebito"));
const AuditoriaIndex = lazy(() => import("../pages/auditoria"));
const FormDadosGridAtividades = lazy(() => import("../pages/analiseAtividade/formDadosGridAtividades"));
const AnaliseAtividadesIndex = lazy(() => import("../pages/analiseAtividade"));
const MensagensPadrao = lazy(() => import("../pages/MensagensPadrao"));
const FilaTrabalho = lazy(() => import('../components/FilaTrabalho'));

export default {
	"/": Dashboard,
	"/home": Dashboard,
	"/empresas": EmpresaIndex,
	"/empresas/adicionar": EmpresaAdicionar,
	"/empresas/editar/:id": EmpresaEditar,
	"/empresas-obrigacoes/:id": EmpresaObrigacaoEditar,
	"/empresas-obrigacoes": EmpresaObrigacaoIndex,
	"/escritorios": EscritorioIndex,
	"/atividades": AtividadeIndex,
	"/atividade/adicionar": AtividadeAdicionar,
	"/atividade/editar/:id": AtividadeEditar,
	"/pessoas-empresas": PessoaEmpresaIndex,
	"/pessoa-empresa/adicionar": PessoaEmpresaAdicionar,
	"/pessoa-empresa/editar/:id/:empresaId": PessoaEmpresaEditar,
	"/permissao-acesso-pessoaEmp/config-permissao/:id": PermissaoAcessoPessoaEmpIndex,
	"/pessoas-escritorios": PessoaEscritorioIndex,
	"/pessoa-escritorio/adicionar": PessoaEscritorioAdicionar,
	"/pessoa-escritorio/editar/:id": PessoaEscritorioEditar,
	"/permissao-acesso-pessoaEsc/configurar-permissao/:id": PermissaoAcessoPessoaEscIndex,
	"/solicitacoes": SolicitacaoIndex,
	"/solicitacao/adicionar": SolicitacaoAdicionar,
	"/solicitacao-editar": SolicitacaoEditar,
	"/grupo-obrigacoes": GrupoObrigacaoIndex,
	"/grupo-obrigacao-adicionar": GrupoObrigacaoAdicionar,
	"/grupo-obrigacao-editar": GrupoObrigacaoEditar,
	"/gestao": PainelGestao,
	"/acesso-nao-autorizado": AcessoNaoAutorizadoIndex,
	"/pesquisa": PesquisaIndex,
	"/auditoria": AuditoriaIndex,
	"/setores-atendimentos": SetorAtendimentoIndex,
	"/setor-atendimento/adicionar": SetorAtendimentoAdicionar,
	"/setor-atendimento/editar/:id": SetorAtendimentoEditar,
	"/certidoes-negativas": CertidaoNegativaDebitoIndex,
	"/certidoes-negativas/movimentacao-cnd/:id": MovimentacaoCNDIndex,
	"/captcha": CaptchaCnd,
	"/gestao-documentos": GestaoDocumentosIndex,
	"/gestao-documentos/editar/:id": GestaoDocumentosPublicados,
	"/analise-atividades": AnaliseAtividadesIndex,
	"/dados-analise-atividades": FormDadosGridAtividades,
	"/edit-info-pessoa-empresa/:id/:empresaId": EditInfoPessoalPessoaEmpresa,
	"/edit-info-pessoa-escritorio/:id/:empresaId": EditInfoPessoalPessoaEscritorio,
	"/gestao-mensagens": MensagensPadrao,
	'/fila-trabalho': FilaTrabalho
};
