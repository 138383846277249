import { FETCH_TOTAIS_CLIENTES } from './../actions/types';

const INITIAL_STATE = {
    userList: [],
    cidades: [],
    setorAtendimento: [],
    empresas: [],
    tipoLogradouro: [],
    empresasPorPessoa: [],
    processandoAlteracaoPermissaoEmpresas: false,
    desabilitaForm: false,
    desabilitaEmail: false,
    isEditInfoPessoalPessoaEmpresa: false,
    selecionarUsuario: false,
    totais: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ESTADO_INICIAL':
            return {
                ...state,
                empresas: [],
                empresasPorPessoa: [],
                userList: [],
                tipoLogradouro: [],
                setorAtendimento: [],
                cidades: [],
                isEditInfoPessoalPessoaEmpresa: false
            }
        case 'USER_LIST':
            return { ...state, userList: action.payload.data.pessoasEmpresas };
        case 'GET_CIDADES':
            return { ...state, cidades: action.payload.data.cidades };
        case 'GET_SETOR_ATENDIMENTO':
            return { ...state, setorAtendimento: action.payload.data };
        case 'GET_EMPRESAS':
            return { ...state, empresas: action.payload.data.empresas };
        case 'GET_TIPOS_LOGRADOUROS':
            return { ...state, tipoLogradouro: action.payload.data };

        case 'GET_EMPRESAS':
            return { ...state, empresas: action.payload.data.empresas }
        case 'GET_EMPRESAS_DISPONIVEIS_POR_SETOR_ATENDIMENTO':
            return { ...state, empresas: action.payload.data }
        case 'GET_EMPRESAS_DISPONIVEIS_RELACIONAMENTO':
            return { ...state, empresas: action.payload.data }
        case 'GET_EMPRESAS_POR_PESSOA':
            return { ...state, empresasPorPessoa: action.payload.data }

        //Processamento de alteração de permissão de empresas
        case 'INICIAR_PROCESSAMENTO_PERMISSAO_EMPRESAS':
            return { ...state, processandoAlteracaoPermissaoEmpresas: true }
        case 'FINALIZAR_PROCESSAMENTO_PERMISSAO_EMPRESAS':
            return { ...state, processandoAlteracaoPermissaoEmpresas: false }

        case 'SET_DESABILITA_FORM_PESSOAEMP':
            return { ...state, desabilitaForm: action.payload }
        case 'SET_DESABILITA_EMAIL_PESSOA_EMPRESA':
            return { ...state, desabilitaEmail: action.payload }
        case 'SET_EHEDIT_INFO_PESSOAL_PESSOAEMP':
            return { ...state, isEditInfoPessoalPessoaEmpresa: action.payload }
        case 'PE_SET_EMPRESA_ID':
            return { ...state, empresaFiltroId: action.payload }
        case 'PE_SET_PESSOAS_EMPRESA':
            return { ...state, userList: action.payload.pessoasEmpresas }
        case 'PE_CARREGANDO_PESSOAS_EMPRESA':
            return { ...state, carregandoPessoasEmpresa: action.payload }
        case FETCH_TOTAIS_CLIENTES: 
            return {...state, totais: action.payload }
        default:
            return state;
    }
};
