import {
  FETCH_NOTIFICACOES_SUCCESS,
  ADICIONA_NOTIFICACAO,
  TOTAL_NOTIFICACAO_NAO_LIDA,
  ATUALIZA_NOTIFICACOES,
} from '../actions/types';

const INITIAL_STATE = {
  notificacoes: [],
  notificacoesNaoLidas: 0,
  totalNotificacoes: 0,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ATUALIZA_NOTIFICACOES:
    case FETCH_NOTIFICACOES_SUCCESS:
      const {
        data: notificacoes,
        notificationsCount: notificacoesNaoLidas,
        count: totalNotificacoes,
      } = action.payload;

      return {
        ...state,
        notificacoes: [...state.notificacoes, ...notificacoes],
        notificacoesNaoLidas,
        totalNotificacoes,
      };
    case ADICIONA_NOTIFICACAO:
      return {
        ...state,
        notificacoes: [action.payload, ...state.notificacoes],
        notificacoesNaoLidas: state.notificacoesNaoLidas + 1,
      };
    case TOTAL_NOTIFICACAO_NAO_LIDA:
      return { ...state, notificacoesNaoLidas: action.payload };
    default:
      return state;
  }
}
