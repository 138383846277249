const INITIAL_STATE = {
    pesquisa: null
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'PESQUISA_ROTA':
            return { ...state, pesquisa: action.payload }
        default:
            return state
    }
}