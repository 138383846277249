import React, { Component } from 'react';

import { Modal } from 'antd';
import $ from 'jquery';
import { connect } from 'react-redux';



import Consts from '../helpers/consts/defaultConsts';
import Util from '../helpers/util';
import pace from './assets/js/pace/pace';
import Loading from "./components/loading";
import Footer from './footer';
import { correctHeight, detectBody } from './helpers/helpers';
import Messages from './messages';
import Navigation from './navigation/index';
import TopHeader from './topHeader';

class Main extends Component {
  componentDidMount() {
    $(window).bind('load resize', () => {
      correctHeight();
      detectBody();
    });

    $('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
    pace.start();
  }

  renderMain() {
    return (
      <div id="wrapper">
        <Navigation />
        <div id="page-wrapper" className="gray-bg">
          <TopHeader />
          {this.props.children}
          <Footer />
          <Messages />
        </div>
      </div>
    );
  }

  render() {
    if (!this.props.usuario.pessoaLogada.nome) {
      return (
        <div>
          <Loading title="Estamos preparando tudo para você! Aguarde..." type="bars" />
          {this.props.children}
        </div>
      );
    }
      if (
        this.props.usuario.pessoaLogada.grupoUsuarioId !== Consts.ID_GRUPO_USUARIO_SUPER_USUARIO
      ) {
        if (
          !this.props.usuario.pessoaLogada.pessoaUsuarioJaExiste ||
          !this.props.usuario.pessoaLogada.status
        ) {
          return (
            <div className="bg-login">
              <Modal.info
                title="Acesso não permitido!"
                width={520}
                content={
                  <div>
                    <p>O usuário encontra-se desabilitado por um dos seguintes motivos:</p>
                    <p>
                      <b>Usuários da empresa contábil</b>
                    </p>
                    <ul>
                      <li>Não foi finalizado o cadastro do usuário;</li>
                      <li>Usuário não faz mais parte da empresa contábil.</li>
                    </ul>
                    <p>
                      <b>Clientes da empresa contábil</b>
                    </p>
                    <ul>
                      <li>Não foi finalizado o cadastro do usuário;</li>
                      <li>Usuário encontra-se inadimplente com a empresa contábil.</li>
                    </ul>
                    <p>Por favor, entre em contato com o administrador do sistema.</p>
                  </div>
                }
                onOk={() => window.location.assign(Util.getUrlSentinela())}
               />
            </div>
          );
        }
          return this.renderMain();

      }
        return this.renderMain();


  }
}

const mapStateToProps = state => ({ logged: state.logged, usuario: state.usuario });
export default connect(mapStateToProps, null, null, { forwardRef: true })(Main);
