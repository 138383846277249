const INITIAL_STATE = {
    solicitacoes: [],
    carregandoSolicitacoes: false,
    atividades: [],
    pessoas: [],
    usuariosSolicitante: [],
    anexos: [],
    empresasRelacionadaAPessoa: []
}
export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ESTADO_INICIAL':
            return { ...state, solicitacoes: [], carregandoSolicitacoes: false, atividades: [], usuariosSolicitante: [], 
                        anexos: [], empresasRelacionadaAPessoa: []}
        case 'GET_USUARIOS_SOLICITANTE':
            return { ...state, usuariosSolicitante: action.payload.data.usuariosSolicitante}
        case 'GET_ATIVIDADES':
            return { ...state, atividades: action.payload.data.atividades }
        case 'GET_PESSOAS':
            return { ...state, pessoas: action.payload.data.pessoas }
        
        //Solicitações
        case 'INICIAR_SOLICITACOES':
            return { ...state, carregandoSolicitacoes: true }
        case 'COMPLETADO_SOLICITACOES':
            return { ...state, solicitacoes: action.result.data.solicitacoes, carregandoSolicitacoes: false }
        case 'SET_LISTA_ANEXOS':
            return { ...state, anexos: action.payload}
        case 'GET_EMPRESAS_RELACIONADA_PESSOA':
            return { ...state, empresasRelacionadaAPessoa: action.payload.data.empresas}
        default:
            return state
    }
}