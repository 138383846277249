const INITIAL_STATE = {
    atividade: {
        descricao: '',
        tipo: 0,
        setor: 0,
        criticidade: 0,
        tarefas: [{
            descricao: '',
            prazo: '',
            hora: '',
            responsavel: 0
        }]
    },
    atividades: [],
    tarefas: [],
    tiposAtividade: [],
    criticidades: [],
    periodicidades: [],
    tiposVencimento: [],
    tiposTratativasVencimento: [],
    tiposResponsavel: [],
    setoresAtendimentos: [],
    prazoTotal: 0,
    horaTotal: '',
    exibeDefinicoesDeVencimento: true,
    periodosVencimentos: [],
    campoVencimento: {},
    disabledPeriodoVencimento: false,
    disabledTipoTrativaVencimento: true,
    desabilitaCriticidade: false,
    checkedClienteAcomp: false,
    showGridTarefa: true,
    clienteAcompanhaDesabilitado: false,
    carregandoAtividades: false,
    ambitosObrigacaoAcessoria: [],
    desabilitaUnidadeFederativa: true,
    ambitoObrigacao: 0,
    exibeAmbitoObrigacaoTributaria: true
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ESTADO_INICIAL':
            return { ...state, clienteAcompanhaDesabilitado: false }
        case 'SET_CHECKBOX_CLIENTE_ACOMPANHA':
            return { ...state, clienteAcompanhaDesabilitado: action.payload }
        case 'GET_ATIVIDADES':
            return { ...state, atividades: action.payload.data.atividades }
        case 'GET_TIPOS_ATIVIDADE':
            return { ...state, tiposAtividade: action.payload.data }
        case 'GET_CRITICIDADES':
            return { ...state, criticidades: action.payload.data }
        case 'GET_PERIODICIDADES':
            return { ...state, periodicidades: action.payload.data }
        case 'GET_TIPOS_VENCIMENTO':
            return { ...state, tiposVencimento: action.payload.data }
        case 'GET_TIPOS_TRATATIVAS_VENCIMENTO':
            return { ...state, tiposTratativasVencimento: action.payload.data }
        case 'GET_TIPOS_RESPONSAVEL':
            return { ...state, tiposResponsavel: action.payload.data }
        case 'GET_SETORES_ATENDIMENTOS':
            return { ...state, setoresAtendimentos: action.payload.data.setoresAtendimentos }
        case 'GET_DEFINICOES_DE_VENCIMENTO':
            return {
                ...state, exibeDefinicoesDeVencimento: action.payload.exibeDefinicoesDeVencimento,
                desabilitaCriticidade: action.payload.desabilitaCriticidade,
                checkedClienteAcomp: action.payload.checkedClienteAcomp,
                exibeAmbitoObrigacaoTributaria: action.payload.exibeAmbitoObrigacaoTributaria
            }
        case 'GET_PERIODOS_VENCIMENTOS':
            return { ...state, periodosVencimentos: action.payload.data }
        case 'SET_CAMPO_VENCIMENTO':
            return {
                ...state, campoVencimento: action.payload.campo,
                disabledPeriodoVencimento: action.payload.disabledPeriodoVencimento,
                disabledTipoTrativaVencimento: action.payload.disabledTipoTrativaVencimento
            }
        case 'SHOW_GRID_TAREFA':
            return { ...state, showGridTarefa: action.payload }
        case 'SET_NOVAS_TAREFAS':
            return { ...state, tarefas: action.payload }
        //Atividades
        case 'INICIAR_ATIVIDADES':
            return { ...state, carregandoAtividades: true }
        case 'COMPLETADO_ATIVIDADES':
            return { ...state, atividades: action.payload.atividades, carregandoAtividades: false }
        case 'FINALIZADO_ATIVIDADES':
            return { ...state, carregandoAtividades: false }
        case 'GET_AMBITOS_OBRIGACAO_ACESSORIA':
            return { ...state, ambitosObrigacaoAcessoria: action.payload.data }
        case 'SET_DISABLED_UNIDADE_FEDERATIVA':
            return { ...state, desabilitaUnidadeFederativa: action.payload }
        case 'SET_CAMPO_AMBITO_OBIRGACAO':
            return { ...state, ambitoObrigacao: action.payload }
        default:
            return state
    }

}