import {
  FETCH_TODAS_EMPRESAS_PESSOA,
  FETCH_TOTAIS_DOCUMENTOS,
  FILTER_EMPRESAS_PESSOA,
  MARCA_DOCUMENTO_LIDO,
} from './../actions/types';

const INITIAL_STATE = {
  empresasParametrizadas: [],
  empresasParametrizadasFiltrado: [],
  empresasAtivas: [],
  carregandoEmpresasParametrizadas: false,
  carregandoDocumentosPorEmpresa: false,
  empresaSelecionada: {},
  documentos: [],
  showModal: false,
  tiposDocumento: [],
  avisarCliente: false,
  avisarClienteNaDataVencimento: false,
  anexo: "",
  defaultFileList: [],
  operacao: "",
  showModalObservacao: false,
  empresaIdFiltrada: "",
  habilitaAvisarCliNoVencimento: false,
  anexos: [],
  totalDocumentos: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GD_INITIAL_STATE":
      return { ...state, empresasParametrizadas: [], documentos: [] };
    case "GD_SET_EMRPESAS_PARAMETRIZADAS":
      return { ...state, empresasParametrizadas: action.payload };
    case "GD_CARREGA_EMPRESAS_PARAMETRIZADAS":
      return { ...state, carregandoEmpresasParametrizadas: true };
    case "GD_COMPLETADO_EMPRESAS_PARAMETRIZADAS":
      return { ...state, carregandoEmpresasParametrizadas: false };
    case "GD_CARREGA_DOCUMENTOS_PUBLICADOS":
      return { ...state, carregandoDocumentosPorEmpresa: true };
    case "GD_COMPLETADO_CARREGAMENTO_DOCUMENTOS_PUBLICADOS":
      return { ...state, carregandoDocumentosPorEmpresa: false };
    case "GD_SET_EMPRESA_SELECIONADA":
      return { ...state, empresaSelecionada: action.payload };
    case "GD_SET_DOCUMENTOS_PUBLICADOS":
      return { ...state, documentos: action.payload };
    case "GD_SHOW_MODAL_DOCUMENTO":
      return { ...state, showModal: action.payload };
    case "GD_GET_TIPOS_DOCUMENTO":
      return { ...state, tiposDocumento: action.payload.data };
    case "GD_SET_AVISAR_CLIENTE":
      return { ...state, avisarCliente: action.payload };
    case "GD_SET_AVISAR_CLIENTE_NA_DT_VENCIMENTO":
      return { ...state, avisarClienteNaDataVencimento: action.payload };
    case "GD_SET_LISTA_ANEXOS_DOCUMENTO":
      return { ...state, anexos: action.payload };
    case "GD_SET_ANEXOS":
      return { ...state, defaultFileList: action.payload };
    case "GD_SET_OPERACAO":
      return { ...state, operacao: action.payload };
    case "GD_SHOW_MODAL_OBSERVACAO":
      return { ...state, showModalObservacao: action.payload };
    case "GD_SET_EMRPESA_ID":
      return { ...state, empresaIdFiltrada: action.payload };
    case "GD_HABILITA_AVISAR_CLIENTE_NO_VENCIMENTO":
      return { ...state, habilitaAvisarCliNoVencimento: action.payload };
    case "GD_TODAS_EMRPESAS_ATIVAS":
      return { ...state, empresasAtivas: action.payload.data.empresas };
    case "GD_SET_ANEXO_DOCUMENTO":
      return { ...state, anexos: [...state.anexos, action.payload] };
    case "GD_SET_DOCUMENTO_ANEXO_STRING":
      return { ...state, anexo: action.payload };
    case "GD_REMOVE_ARQUIVO_UPLOAD":
      return {
        ...state,
        anexos: state.anexos.filter(file => file.uid !== action.payload.uid)
      };
    case FETCH_TOTAIS_DOCUMENTOS:
      return { ...state, totalDocumentos: action.payload };
    case MARCA_DOCUMENTO_LIDO:
      return { ...state, documentos: action.payload };
    case FETCH_TODAS_EMPRESAS_PESSOA:
      return {
        ...state,
        empresasParametrizadas: action.payload,
        empresasParametrizadasFiltrado: action.payload
      };
    case FILTER_EMPRESAS_PESSOA:
      return { ...state, empresasParametrizadasFiltrado: action.payload };
    default:
      return state;
  }
}
