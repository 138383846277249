import { GET_MENSAGENS_PADRAO } from "../actions/types";
const INITIAL_STATE = {
  mensagens: []
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "SET_ESTADO_INICIAL":
      return { ...INITIAL_STATE };
    case GET_MENSAGENS_PADRAO:
      return { ...state, mensagens: action.payload };
    default:
      return state;
  }
}
