import React from 'react';
import NotificacaoItem from './NotificacaoItem';
import PropTypes from 'prop-types';

const propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  dataSource: [],
};

const NotificacaoItemList = ({ dataSource }) => {
  return dataSource.map(notificacao => (
    <React.Fragment key={notificacao.id}>
      <li>
        <NotificacaoItem item={notificacao} />
      </li>
      <li className="dropdown-divider m-0 bt-grey"></li>
    </React.Fragment>
  ));
};
NotificacaoItemList.propTypes = propTypes;
NotificacaoItemList.defaultProps = defaultProps;

export default NotificacaoItemList;
