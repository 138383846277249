import _ from 'lodash';
import {
  FETCH_TOTAIS_COLABORADORES,
  FETCH_PESSOA_DETALHES,
  UPLOAD_COLABORADOR_PROFILE_PICTURE_SUCCESS,
} from './../actions/types';

const INITIAL_STATE = {
  showModalAjuda: false,
  pessoasEscritorios: [],
  empresas: [],
  empresasPorPessoaEsc: [],
  showPermissoes: false,
  emailDesabilitado: false,
  foto: 'https://www.filecluster.com/howto/wp-content/uploads/2014/07/User-Default.jpg',
  grupoUsuario: [],
  ehTipoUsuarioAdministrador: false,
  ehSupervisorSetor: false,
  processandoAlteracaoPermissaoEmpresas: false,
  processandoAlteracaoPermissaoEmpresasEsc: false,
  isEditInfoPessoal: false,
  tiposPessoasEsc: [],
  visualizarInativos: false,
  totais: {},
  pessoaDetalhes: {},
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ESTADO_INICIAL':
      return {
        ...state,
        showLoading: true,
        pessoasEscritorios: [],
        empresas: [],
        showPermissoes: false,
        empresasPorPessoaEsc: [],
        emailDesabilitado: false,
        showModalAjuda: false,
        ehTipoUsuarioAdministrador: false,
        ehSupervisorSetor: false,
        processandoAlteracaoPermissaoEmpresasEsc: false,
        isEditInfoPessoal: false,
        pessoaDetalhes: {},
      };
    case 'SET_TIPO_USUARIO':
      return { ...state, ehTipoUsuarioAdministrador: action.payload };
    case 'SET_FOTO':
      return { ...state, foto: action.payload };
    case 'GET_PESSOAS_ESCRITORIOS':
      return { ...state, pessoasEscritorios: action.payload.pessoasEscritorios };
    case 'SHOW_PERMISSOES':
      return { ...state, showPermissoes: action.payload };
    case 'GET_EMPRESAS':
      return { ...state, empresas: action.payload.data.empresas };
    case 'SET_EMPRESAS':
      return { ...state, empresas: [] };
    case 'GET_EMPRESAS_DISPONIVEIS_POR_SETOR_ATENDIMENTO':
      return { ...state, empresas: action.payload.data.empresas };
    case 'GET_EMPRESAS_POR_PESSOA_ESC':
      return { ...state, empresasPorPessoaEsc: action.payload.data };
    case 'SET_DESABILITA_EMAIL':
      return { ...state, emailDesabilitado: action.payload };
    case 'SHOW_MODAL_AJUDA':
      return { ...state, showModalAjuda: action.payload };
    case 'GET_GRUPO_USUARIO':
      return { ...state, grupoUsuario: action.payload.data.grupoUsuarios };
    case 'SET_SUPERVISOR_SETOR':
      return { ...state, ehSupervisorSetor: action.payload };
    //Processamento de alteração de permissão de empresas
    case 'INICIAR_PROCESSAMENTO_PERMISSAO_EMPRESAS_ESC':
      return { ...state, processandoAlteracaoPermissaoEmpresasEsc: true };
    case 'FINALIZAR_PROCESSAMENTO_PERMISSAO_EMPRESAS_ESC':
      return { ...state, processandoAlteracaoPermissaoEmpresasEsc: false };
    case 'SET_EHEDIT_INFO_PESSOAL':
      return { ...state, isEditInfoPessoal: action.payload };
    case 'PESC_GET_TIPOS_PESSOAS':
      return { ...state, tiposPessoasEsc: _.remove(action.payload, item => item.id < 3) };
    case 'PESC_SET_SETOR_ATENDIMENTO_ID':
      return { ...state, setorAtendimentoFiltroId: action.payload };
    case 'PESC_SET_PESSOAS_ESCRITORIO':
      return { ...state, pessoasEscritorios: action.payload.pessoasEscritorios };
    case 'PESC_SET_CARREGANDO_PESSOAS_ESCRITORIO':
      return { ...state, carregandoPessoasEscritorio: action.payload };
    case 'GET_EMPRESAS_DISPONIVEIS':
      return { ...state, empresas: action.payload.data.empresas.map(item => item.empresa) };
    case FETCH_TOTAIS_COLABORADORES:
      return { ...state, totais: action.payload };
    case FETCH_PESSOA_DETALHES:
      return { ...state, pessoaDetalhes: action.payload };
    case UPLOAD_COLABORADOR_PROFILE_PICTURE_SUCCESS:
      return { ...state, foto: action.paylaod };
    default:
      return state;
  }
}
