import { applyMiddleware, createStore, compose } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
//import tron from '../ReactotronConfig';

import reducers from '../reducers/reducers';

const middlewares = [thunk, multi, promise];

const enhancers = compose(
  applyMiddleware(...middlewares),
  //   tron.createEnhancer(), // bug Reacotron - Dashboard
);

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

// const store = tron.createStore(reducers, devTools, applyMiddleware(thunk, multi, promise));

const store =
  process.env.NODE_ENV === 'local'
    ? createStore(reducers, devTools, enhancers)
    : createStore(reducers, devTools, applyMiddleware(...middlewares));

export default store;
