import Hash from 'react-router-history';


export function pesquisaRota(pesquisa) {
    const { pathname } = Hash.hashHistory.getCurrentLocation();
    if (pathname !== '/pesquisa') Hash.hashHistory.push('/pesquisa');
    
    return  { 
        type: 'PESQUISA_ROTA',
        payload: pesquisa 
    }
}
