import moment from 'moment';

const INITIAL_STATE = {
  dataInicial: moment().startOf('month'),
  dataFinal: moment().endOf('month'),
  statusAnaliseAtividades: [],
  filtroEmpresasAnaliseAtividades: [],
  filtroAtividadesAnaliseAtividades: [],
  filtroSituacaoAnaliseAtividades: null,
  itens: {},
  dadosAtividadesSintetizados: [],
  showModalDetalhesAtividade: false,
  solicitacaoDaMovimentacao: null,
  mensagens: [],
  carregandoSolicitacao: false,
  carregandoNotificacoes: false,
  obrigacaoSelecionada: {},
};

export default function(state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case 'ANA_SET_ESTADO_INICIAL':
      return {
        ...state,
        filtroEmpresasAnaliseAtividades: [],
        filtroAtividadesAnaliseAtividades: [],
        dataInicial: moment().startOf('month'),
        dataFinal: moment().endOf('month'),
      };
    case 'ANA_SET_DATA_INICIAL':
      return { ...state, dataInicial: payload };
    case 'ANA_SET_DATA_FINAL':
      return { ...state, dataFinal: payload };
    case 'GET_STATUS_ANALISE_ATIVIDADES':
      return { ...state, statusAnaliseAtividades: payload.data };
    case 'SET_FILTRO_EMPRESAS_ANALISE_ATIVIDADES':
      return { ...state, filtroEmpresasAnaliseAtividades: payload };
    case 'SET_FILTRO_ATIVIDADES_ANALISE_ATIVIDADES':
      return { ...state, filtroAtividadesAnaliseAtividades: payload };
    case 'SET_FILTRO_SITUACAO_ANALISE_ATIVIDADES':
      return { ...state, filtroSituacaoAnaliseAtividades: payload };
    case 'SET_MENSAGENS_ANALISE_ATIVIDADE':
      return { ...state, mensagens: payload };
    case 'SET_DADOS_ATIVIDADES_SINTETIZADOS':
      return { ...state, dadosAtividadesSintetizados: payload };
    case 'COMPLETADO_ANALISE_ATIVIDADES':
      return { ...state, itens: payload };
    case 'SHOW_MODAL_DETALHES_ATIVIDADE':
      return { ...state, showModalDetalhesAtividade: payload };
    case 'SET_SOLICITACAO_ANALISE_ATIVIDADE':
      return { ...state, solicitacaoDaMovimentacao: payload.data };
    case 'GET_MENSAGEM_ANALISE_ATIVIDADES':
      return { ...state, mensagens: payload.data.mensagens };
    case 'CARREGANDO_SOLICITACOES':
      return { ...state, carregandoSolicitacao: payload };
    case 'CARREGANDO_NOTIFICACOES':
      return { ...state, carregandoNotificacoes: payload };
    case 'SET_OBRIGACAO_SELECIONADA':
      return { ...state, obrigacaoSelecionada: payload };
    case 'GET_COMENTARIOS_ANALISE_ATIVIDADES':
      return { ...state, comentarios: payload.data.comentarios };
    case 'CARREGANDO_COMENTARIOS':
      return { ...state, carregandoComentarios: payload };
    default:
      return state;
  }
}
