export const required = value => (value ? undefined : 'Campo requerido.');
export const validateSetorAtendimento = value =>
  value && value.length ? undefined : 'Campo requerido.';
export const cpf = value => (value && validaCpf(value) ? undefined : 'CPF Inválido.');
export const cnpj = value => (value && validaCnpj(value) ? undefined : 'CNPJ Inválido.');
export const maxLength = max => value =>
  value && value.length > max
    ? `O campo ultrapassou o máximo permitido de ${max} caractere(s).`
    : undefined;
export const maxLength10 = maxLength(10);
export const maxLength18 = maxLength(18);
export const maxLength20 = maxLength(20);
export const maxLength40 = maxLength(40);
export const maxLength60 = maxLength(60);
export const maxLength80 = maxLength(80);
export const maxLength85 = maxLength(85);
export const maxLength100 = maxLength(100);
export const maxLength200 = maxLength(200);
export const maxLength250 = maxLength(250);

export const number = value =>
  value && isNaN(Number(value)) ? 'O campo aceita somente números.' : undefined;
export const minValue = min => value =>
  value && value < min ? `O campo aceita o minímo de ${min} caractere(s).` : undefined;
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Email inválido' : undefined;

export const cpfCnpj = value => {
  if (value) {
    value = value.replace(/[^0-9]/g, '');
    if (value.length == 14) {
      if (validaCnpj(value)) {
        return undefined;
      } else {
        return 'Inscrição inválida.';
      }
    } else if (value.length == 11) {
      if (validaCpf(value)) {
        return undefined;
      } else {
        return 'Inscrição inválida.';
      }
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export function validaCnpj(cnpj) {
  if (!cnpj) return false;

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function validaCpf(cpf) {
  if (!cpf) return false;

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}

export const validateTelefones = values => {
  const errors = {};

  const telFixo = values.telefoneFixo ? values.telefoneFixo.replace(/[^0-9]+/g, '') : '';
  const telCelular = values.telefoneCelular ? values.telefoneCelular.replace(/[^0-9]+/g, '') : '';

  if (telFixo && telFixo.length < 10) {
    errors.telefoneFixo = 'O número do telefone fixo deve possuir 10 digitos.';
  }

  if (telCelular && telCelular.length < 11) {
    errors.telefoneCelular = 'O número do telefone celular deve possuir 11 digitos.';
  }

  return errors;
};

export const fileRequired = value =>
  value ? undefined : 'É necessario informar ao menos um arquivo.';
