import styled from 'tachyons-components';

export const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100 bg-blue`;

export const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

export const Prop = styled('h3')`
f5 f4-ns mb0 white`;

export const Title = styled('h1')`
f4 f3-ns white w-100 tc`;

export const SOLICITACAO_FILE_LIST_TYPES = [
  'application/pdf',
  'text/plain',
  'image/png',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/vnd.oasis.opendocument.text',
  'text/ofc',
  'application/x-ofx',
];
