import React from 'react';

import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import { Provider } from 'react-redux';

import store from './store';
import Home from './theme/home';

function App() {
  return (
    <ConfigProvider locale={ptBR}>
      <Provider store={store}>
        <Home />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
