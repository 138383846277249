export const FETCH_TOTAIS_CLIENTES = 'fetch_totais_clientes';
export const FETCH_TOTAIS_COLABORADORES = 'fetch_totais_colaboradores';
export const FETCH_TOTAIS_DOCUMENTOS = 'fetch_totais_documentos';
export const MARCA_DOCUMENTO_LIDO = 'marca_documento_lido';
export const FETCH_TODAS_EMPRESAS_PESSOA = 'fetch_todas_empresas_pessoa';
export const FILTER_EMPRESAS_PESSOA = 'filter_empresas_pessoa';
export const FETCH_PESSOA_DETALHES = 'fetch_pessoa_detalhes';
export const FETCH_EMPRESAS_SUCCESS = 'fetch_empresas_success';
export const FETCH_EMPRESAS_FAIL = 'fetch_empresas_fail';
export const GET_MENSAGENS_PADRAO = 'get_mensagens_padrao';
export const FETCH_EMPRESAS_OBRIGACOES = 'fetch_empresas_obrigacoes';
export const CONCLUIR_TAREFA = 'concluir_tarefa';
export const REABRIR_TAREFA = 'reabrir_tarefa';
export const UPDATE_TAREFA_SUCCESS = 'UPDATE_TAREFA_SUCCESS';
export const UPDATE_TODAS_TAREFAS_SUCCESS = 'UPDATE_TODAS_TAREFAS_SUCESS';
export const UPDATE_TODAS_TAREFAS_FAIL = 'UPDATE_TODAS_TAREFAS_FAIL';
export const UPDATE_TAREFA_FAIL = 'UPDATE_TAREFA_FAIL';
export const SHOW_TAREFAS_CONCLUIDAS = 'show_tarefas_concluidas';
export const FETCH_OBRIGACAO_TAREFAS_SUCESSO = 'fetch_obrigacao_tarefas_sucesso';
export const FETCH_OBRIGACAO_TAREFAS_FALHA = 'fetch_obrigacao_tarefas_falha';
export const FETCH_PENDENCIAS_SUCCESS = 'FETCH_PENDENCIAS_SUCCESS';
export const FETCH_PENDENCIAS_FAIL = 'FETCH_PENDENCIAS_FAIL';
export const ALTERA_STATUS_FAVORITO_SUCCESS = 'ALTERA_STATUS_FAVORITO_SUCCESS';
export const ALTERA_STATUS_FAVORITO_FAIL = 'ALTERA_STATUS_FAVORITO_FAIL';
export const FT_ALTERA_STATUS_FAVORITO_SUCCESS = 'FT_ALTERA_STATUS_FAVORITO_SUCCESS';
export const VERIFICA_ATIVIDADE_EXCLUIVEL_SUCCESS = 'VERIFICA_ATIVIDADE_EXCLUIVEL_SUCCESS';
export const VERIFICA_ATIVIDADE_EXCLUIVEL_FAIL = 'VERIFICA_ATIVIDADE_EXCLUIVEL_FAIL';
export const UPDATE_RESPONSAVEIS_MOVIMENTACAO_SUCCESS = 'UPDATE_RESPONSAVEIS_MOVIMENTACAO_SUCCESS';
export const UPDATE_RESPONSAVEIS_MOVIMENTACAO_FAIL = 'UPDATE_RESPONSAVEIS_MOVIMENTACAO_FAIL';
export const FETCH_EMPRESA_PARAMETROS_SUCCESS = 'FETCH_EMPRESA_PARAMETROS_SUCCESS';
export const FETCH_EMPRESA_PARAMETROS_FAIL = 'FETCH_EMPRESA_PARAMETROS_FAIL';
export const FETCH_EMPRESA_OBRIGACOES_SUCCESS = 'FETCH_EMPRESA_OBRIGACOES_SUCCESS';
export const FETCH_EMPRESA_OBRIGACOES_FAIL = 'FETCH_EMPRESA_OBRIGACOES_FAIL';
export const FETCH_EMPRESA_SUCCESS = 'FETCH_EMPRESA_SUCCESS';
export const FETCH_EMPRESA_FAIL = 'FETCH_EMPRESA_FAIL';
export const FETCH_OBRIGACAO_EMPRESA_SUCCESS = 'FETCH_OBRIGACAO_EMPRESA_SUCCESS';
export const FETCH_OBRIGACAO_EMPRESA_FAIL = 'FETCH_OBRIGACAO_EMPRESA_FAIL';
export const FETCH_NUMERO_ULTIMA_VERSAO_SUCCESS = 'FETCH_NUMERO_ULTIMA_VERSAO_SUCCESS';
export const FETCH_NUMERO_ULTIMA_VERSAO_FAIL = 'FETCH_NUMERO_ULTIMA_VERSAO_FAIL';
export const PROCESSAR_OBRIGACOES_SUCCESS = 'PROCESSAR_OBRIGACOES_SUCCESS';
export const PROCESSAR_OBRIGACOES_FAIL = 'PROCESSAR_OBRIGACOES_FAIL';
export const LIMPAR_EMPRESA_OBRIGACOES = 'LIMPAR_EMPRESA_OBRIGACOES';
export const UPDATE_MOVIMENTACAO_OBRIGACAO_SUCCESS = 'UPDATE_MOVIMENTACAO_OBRIGACAO_SUCCESS';
export const UPDATE_MOVIMENTACAO_OBRIGACAO_FAIL = 'UPDATE_MOVIMENTACAO_OBRIGACAO_FAIL';
export const SET_COPIA_ITENS_OBRIGACAO_EMPRESA = 'SET_COPIA_ITENS_OBRIGACAO_EMPRESA';
export const FETCH_NUMERO_VERSOES_OBRIGACAO_SUCCESS = 'FETCH_NUMERO_VERSOES_OBRIGACAO_SUCCESS';
export const FETCH_NUMERO_VERSOES_OBRIGACAO_FAIL = 'FETCH_NUMERO_VERSOES_OBRIGACAO_FAIL';

export const FETCH_COMENTARIOS_SUCCESS = 'FETCH_COMENTARIOS_SUCCESS';
export const FETCH_COMENTARIOS_FAIL = 'FETCH_COMENTARIOS_FAIL';
export const FETCH_CLIENTES_SUCCESS = 'FETCH_CLIENTES_SUCCESS';
export const FETCH_CLIENTES_FAIL = 'FETCH_CLIENTES_FAIL';
export const FETCH_PESSOAS_ESCRITORIO_SUCCESS = 'FETCH_PESSOAS_ESCRITORIO_SUCCESS';
export const FETCH_PESSOAS_ESCRITORIO_FAIL = 'FETCH_PESSOAS_ESCRITORIO_FAIL';
export const FETCH_MENSAGENS_FAIL = 'FETCH_MENSAGENS_FAIL';
export const FETCH_MENSAGENS_SUCCESS = 'FETCH_MENSAGENS_SUCCESS';
export const ALTERA_RESPONSAVEL_SUCCESS = 'ALTERA_RESPONSAVEL_SUCCESS';
export const ALTERA_RESPONSAVEL_FAIL = 'ALTERA_RESPONSAVEL_FAIL';
export const ADICIONA_COMENTARIO_SUCCESS = 'ADICIONA_COMENTARIO_SUCCESS';
export const ADICIONA_COMENTARIO_FAIL = 'ADICIONA_COMENTARIO_FAIL';
export const ADICIONA_MENSAGEM_SUCCESS = 'ADICIONA_MENSAGEM_SUCCESS';
export const ADICIONA_MENSAGEM_FAIL = 'ADICIONA_MENSAGEM_FAIL';
export const EXCLUIR_MOVIMENTACAO_ATIVIDADE_SUCCESSO = 'EXCLUIR_MOVIMENTACAO_ATIVIDADE_SUCCESSO';
export const EXCLUIR_MOVIMENTACAO_ATIVIDADE_FAIL = 'EXCLUIR_MOVIMENTACAO_ATIVIDADE_FAIL';
export const SET_ATIVIDADES_DRAWER_VISIBILE = 'SET_ATIVIDADES_DRAWER_VISIBILE';
export const SET_CURRENT_ROW = 'SET_CURRENT_ROW';
export const SET_ALL_ATIVIDADES_EMPRESA_SELECIONADA = 'SET_ALL_ATIVIDADES_EMPRESA_SELECIONADA';
export const FETCH_SOLICITACAO_SUCCESS = 'FETCH_SOLICITACAO_SUCCESS';
export const FETCH_SOLICITACAO_FAIL = 'FETCH_SOLICITACAO_FAIL';
export const FETCH_RESPONSAVEIS_MOVIMENTACAO_SUCCESS = 'FETCH_RESPONSAVEIS_MOVIMENTACAO_SUCCESS';
export const FETCH_RESPONSAVEIS_MOVIMENTACAO_FAIL = 'FETCH_RESPONSAVEIS_MOVIMENTACAO_FAIL';

export const FETCH_NOTIFICACOES_SUCCESS = 'FETCH_NOTIFICACOES_SUCCESS';
export const FETCH_NOTIFICACOES_FAIL = 'FETCH_NOTIFICACOES_FAIL';
export const REGISTRA_NOTIFICACAO_SUCCESS = 'REGISTRA_NOTIFICACAO_SUCCESS';
export const REGISTRA_NOTIFICACAO_FAIL = 'REGISTRA_NOTIFICACAO_FAIL';
export const ADICIONA_NOTIFICACAO = 'ADICIONA_NOTIFICACAO';
export const ATUALIZA_NOTIFICACOES = 'ATUALIZA_NOTIFICACOES';
export const TOTAL_NOTIFICACAO_NAO_LIDA = 'TOTAL_NOTIFICACAO_NAO_LIDA';
export const MARCAR_NOTIFICACAO_LIDO_SUCCESS = 'MARCAR_NOTIFICACAO_LIDO_SUCCESS';
export const MARCAR_NOTIFICACAO_LIDO_FAIL = 'MARCAR_NOTIFICACAO_LIDO_FAIL';

export const CBR_SET_ESTADO_INICIAL = 'CBR_SET_ESTADO_INICIAL';
export const CBR_PROCESSANDO = 'CBR_PROCESSANDO';
export const CBR_SET_PLANO_CONTRATADO = 'CBR_SET_PLANO_CONTRATADO';
export const UPLOAD_COLABORADOR_PROFILE_PICTURE_SUCCESS =
  'UPLOAD_COLABORADOR_PROFILE_PICTURE_SUCCESS';
export const UPLOAD_COLABORADOR_PROFILE_PICTURE_FAIL = 'UPLOAD_COLABORADOR_PROFILE_PICTURE_FAIL';
export const FETCH_RESPONSAVEIS_SETORES_EMPRESA_SUCCESS =
  'FETCH_RESPONSAVEIS_SETORES_EMPRESA_SUCCESS';
export const FETCH_RESPONSAVEIS_SETORES_EMPRESA_FAIL = 'FETCH_RESPONSAVEIS_SETORES_EMPRESA_FAIL';
export const RESPONSAVEIS_SETORES_EMPRESA = 'RESPONSAVEIS_SETORES_EMPRESA';
export const ATUALIZA_LISTA_RESPONSAVEIS_SETORES_EMPRESA =
  'ATUALIZA_LISTA_RESPONSAVEIS_SETORES_EMPRESA';
export const LIMPAR_LISTA_RESPONSAVEIS_SETORES_EMPRESA =
  'LIMPAR_LISTA_RESPONSAVEIS_SETORES_EMPRESA';

export const UPDATE_RESPONSAVEIS_OBRIGACAO_SUCCESS = 'UPDATE_RESPONSAVEIS_OBRIGACAO_SUCCESS';
export const UPDATE_RESPONSAVEIS_OBRIGACAO_FAIL = 'UPDATE_RESPONSAVEIS_OBRIGACAO_FAIL';
export const SET_EMPRESAS_COLABORADOR = 'SET_EMPRESAS_COLABORADOR';
export const SET_PARAMETRO_SELECIONADO = 'SET_PARAMETRO_SELECIONADO';
export const ATUALIZA_RESPONSAVEIS_MOVIMENTACAO_SUCCESS =
  'ATUALIZA_RESPONSAVEIS_MOVIMENTACAO_SUCCESS';
export const ATUALIZA_TODOS_RESPONSAVEIS_MOVIMENTACAO_SUCCESS =
  'ATUALIZA_TODOS_RESPONSAVEIS_MOVIMENTACAO_SUCCESS';
export const ATUALIZA_TODOS_RESPONSAVEIS_MOVIMENTACAO_FAIL =
  'ATUALIZA_TODOS_RESPONSAVEIS_MOVIMENTACAO_FAIL';
export const CONSULTA_CERTIDAO_SUCCESS = 'CONSULTA_CERTIDAO_SUCCESS';
export const CONSULTA_CERTIDAO_FAIL = 'CONSULTA_CERTIDAO_FAIL';
export const FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS = 'FETCH_MOVIMENTACOES_CERTIDOES_SUCCESS';
export const FETCH_MOVIMENTACOES_CERTIDOES_FAIL = 'FETCH_MOVIMENTACOES_CERTIDOES_FAIL';
export const LOADING_OBRIGACOES_START = 'LOADING_OBRIGACOES_START';
export const LOADING_OBRIGACOES_DONE = 'LOADING_OBRIGACOES_DONE';

export const UPDATE_PESSOA_LOGADA = 'UPDATE_PESSOA_LOGADA';
export const UPDATE_EMPRESA_OBRIGACAO_STATUS = 'UPDATE_EMPRESA_OBRIGACAO_STATUS';
export const FILTRAR_EMPRESAS = 'FILTRAR_EMPRESAS';
export const FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_SUCCESS =
  'FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_SUCCESS';
export const FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_FAIL =
  'FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_FAIL';

export const FETCH_TODOS_COLABORADORES_SUCCESS = 'FETCH_TODOS_COLABORADORES_SUCCESS';
export const FETCH_TODOS_COLABORADORES_FAIL = 'FETCH_TODOS_COLABORADORES_FAIL';

export const SET_ATUAL_PE_VIEW = 'SET_ATUAL_PE_VIEW';
