const INITIAL_STATE = {
    periodoSelecionado: '',
    processandoPeriodo: false,
    mostrarDadosInquilino: false,
    processandoInquilino: false,
    processandoCND: false,
    inquilino: {},
    inquilinos: []
}

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_PERIODO_SELECIONADO':
            return { ...state, periodoSelecionado: action.payload}
        

    //Processamento
    case 'INICIAR_PROCESSAMENTO':
         return { ...state, processandoPeriodo: true }
    case 'FINALIZAR_PROCESSAMENTO':
         return { ...state, processandoPeriodo: false }

    //Inquilinos
    case 'MOSTRAR_DADOS_INQUILINO':
        return { ...state, mostrarDadosInquilino: action.payload }
    case 'INICIAR_PROCESSAMENTO_INQUILINO':
         return { ...state, processandoInquilino: true }
    case 'FINALIZAR_PROCESSAMENTO_INQUILINO':
         return { ...state, processandoInquilino: false }
    case 'GET_INQUILINO':
        return { ...state, inquilino: action.payload }
    case 'GET_INQUILINOS':
        return { ...state, inquilinos: action.payload.data.inquilinos }
    case 'INICIAR_PROCESSAMENTO_CND':
        return { ...state, processandoCND: true }
    case 'FINALIZAR_PROCESSAMENTO_CND':
        return { ...state, processandoCND: false }
    default:
            return state

    }
}

