import axios from 'axios';
import { toastr } from 'react-redux-toastr';

import tgcw from '../api/tgcw';
import Util from '../helpers/util';
import { signalR } from './notificacaoActions';

export const getPessoaLogada = () => async dispatch => {
    
    try {
       const response = await tgcw.get(`/pessoas-usuarios/pessoa-logada`);
       const { data } = response;

       if (!data.empresaId) {
            dispatch({ type: 'GET_PESSOA_LOGADA', payload: data })
       }
       const { data: isEscritorio } = await getIsEscritorio(data.empresaId);
       data.isEscritorio = isEscritorio;

       dispatch({ type: 'GET_PESSOA_LOGADA', payload: data });
       dispatch(signalR());

    }catch(error){
        if (error.response && error.response.data) {
            // toastr.warning('Dados do Usuário', `${error.response.data.erro || 'Opps... Não foi possível buscar os dados do usuário'}`, { timeOut: 8000 });
            console.log(error.data)
        } else {
            toastr.error('Dados do Usuário', `${error.message || 'Opps... Não foi possível buscar os dados do usuário'}`, { timeOut: 8000 });
        }
    }
}

export const getIsEscritorio = async (empresaId) => {
    try{
        return await tgcw.get(`/empresas/pessoa-isEscritorio?empresaId=${empresaId}`);
    }catch(err) {
        console.log(err);
    }
   
}

export function setEstadoInicialUsuarioLogado() {
    return {
        type: 'SET_ESTADO_INICIAL_USUARIO_LOGADO'
    }
}

export const verificaPessoaLogada = async () => {
    return (dispatch, getState) => {
        const { usuario } = getState()
        dispatch({ type: 'USER_SET_PESSOA_JA_LOGADA' }, usuario.id ? true : false)
    }
}

export const getUsuariosDisponiveis = () => {
    const request = axios.get(`${Util.getBaseUrl()}/pessoas-usuarios/usuarios`, Util.getHeaders());
    return {
        type: 'USER_GET_USUARIOS_DISPONIVEIS',
        payload: request
    }
}