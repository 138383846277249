export default function swalProcessingConfig(processingMessage, doneMessage = "") {
  return {
    processing: {
      icon: 'question',
      iconHtml: '<i class="fa fa-spinner fa-spin fa-fw"></i>',
      text: processingMessage,
      allowOutsideClick: false,
      showConfirmButton: false,
      customClass: {
        icon: 'no-border',
      },
    },
    done: {
      text: doneMessage,
      icon: 'success',
      allowOutsideClick: false,
      confirmButtonColor: '#19B394',
      customClass: {
        actions: 'justify-content-flex-end',
      },
    },
  };
}
