export default function LoginReducer(state = !!sessionStorage.token, action) {  
    switch(action.type) {
      case 'LOG_IN_SUCCESS':
        return !!sessionStorage.token
      case 'LOG_OUT':
        return !!sessionStorage.token
      case 'LOG_IN_FAIL':
        return !!sessionStorage.token
      default: 
        return state;
    }
}