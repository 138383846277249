import _ from 'lodash';

import sentinelaURLs from '../util/sentinelaBack';

class Util {
  static getBaseUrl() {

    return process.env.REACT_APP_API_BASE_URL
  }

  static getBaseUrlSentinela() {
    return sentinelaURLs[process.env.NODE_ENV];
  }

  static getUrlSentinela() {
    return sentinelaURLs[process.env.NODE_ENV];
  }

  static getHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        Accept: 'application/json',
        ServiceIdentify: `${sessionStorage.tenant}`,
      },
    };
  }

  static getHeadersFunctionName(functionName) {
    return {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        Accept: 'application/json',
        NomeFuncao: functionName,
        ServiceIdentify: `${sessionStorage.tenant}`,
      },
    };
  }

  static getHeaders2(token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        ServiceIdentify: `${sessionStorage.tenant}`,
      },
    };
  }

  static getHeaders3() {
    return {
      headers: { Authorization: `Bearer ${sessionStorage.token}`, Accept: 'application/json' },
    };
  }

  static possuiPermissao(controller, action) {
    let temPermissao = true;
    const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));
    _.forEach(permissoes, (permissao) => {
      if (permissao.rota === controller) {
        _.forEach(permissao.operacoes, (operacao) => {
          if (operacao.rota === action && !operacao.permitido) {
            temPermissao = false;
          }
        });
      }
    });

    return temPermissao;
  }
}

export default Util;
