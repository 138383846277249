export default {
  ID_GRUPO_USUARIO_SUPER_USUARIO: '315ff38e-d541-4835-9193-0ccd8f2c4619',
  MENSAGEM_CADASTRO_INCOMPLETO:
    'É necessário que o usuário administrador finalize o seu cadastro de usuário acessando o módulo Tron Smart.',
  MENSAGEM_CADASTRO_DESATIVADO:
    'O usuário está desabilitado ou não finalizou o cadastro, entre em contato com o administrador.',

  obrigacaoNaoProcessada:
    'A obrigação não está na movimentação. Acione o botão "Processar obrigações" no topo da página.',
  versaoNaoAplicada:
    'Versão não aplicada na movimentção. Para aplicar os ajustes na movimentação clique nos 3 pontos (na lateral direita) e depois em "Aplicar ajustes".',

  gerirApartir:
    'Defina a data que represente a partir de qual competência deseja fazer gestão da empresa no Tron Smart. Ex.: Deseja entregar as atividades no mês calendário 02/2020 (Ref. competência 01/2020), preencha assim: 01/01/2020.',

  tooltipColor: 'rgb(201, 195, 195)',
};
