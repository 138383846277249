/**
 * Reducer com todos os dados padrões
 */

const INITIAL_STATE = {
  basesDadosNome: '',
  basesDados: [],
  modalVisible: false,
  tiposPessoas: [],
  cidades: [],
  cnaes: [],
  naturezasJuridicas: [],
  ramosAtividades: [],
  regimesTributacoes: [],
  tiposAtividades: [],
  criticidades: [],
  periodicidades: [],
  tiposVencimentos: [],
  tiposTratativasVencimentos: [],
  tiposOperacoes: [],
  setoresAtendimento: [],
  setoresAtendimentoFiltrado: [],
  tiposInscricoes: [],
  ramosAtividades: [],
  atividades: [],
  statusObrigacoes: [],
  showLoading: false,
  estados: [],
  escritorio: {
    logoTipo: '',
    razaoSocial: '',
    nomeFantasia: '',
  },
  empresasClientes: [],
  todasEmpresasAtivas: [],
  selectUser: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DFT_SET_EMPRESAS':
      return { ...state, basesDados: action.payload };
    case 'DFT_SET_EMPRESAS_NOME':
      return { ...state, basesDadosNome: action.payload };
    case 'DFT_SET_MODAL_VISIBLE':
      return { ...state, modalVisible: action.payload };
    case 'GET_TIPOS_PESSOAS':
      return { ...state, tiposPessoas: action.payload.data };
    case 'GET_CIDADES':
      return { ...state, cidades: action.payload.data.cidades };
    case 'GET_CNAES':
      return { ...state, cnaes: action.payload.data.cnaes };
    case 'GET_NATUREZAS_JURIDICAS':
      return { ...state, naturezasJuridicas: action.payload.data.naturezasJuridicas };
    case 'GET_RAMOS_ATIVIDADES':
      return { ...state, ramosAtividades: action.payload.data };
    case 'GET_REGIMES_TRIBUTACOES':
      return { ...state, regimesTributacoes: action.payload.data };
    case 'GET_TIPOS_ATIVIDADES':
      return { ...state, tiposAtividades: action.payload.data };
    case 'GET_CRITICIDADES':
      return { ...state, criticidades: action.payload.data };
    case 'GET_PERIODICIDADES':
      return { ...state, periodicidades: action.payload.data };
    case 'GET_TIPOS_VENCIMENTOS':
      return { ...state, tiposVencimentos: action.payload.data };
    case 'GET_TIPOS_TRATATIVAS_VENCIMENTOS':
      return { ...state, tiposTratativasVencimentos: action.payload.data };
    case 'GET_TIPOS_RESPONSAVEIS':
      return { ...state, tiposResponsaveis: action.payload.data };
    case 'GET_TIPOS_OPERACOES':
      return { ...state, tiposOperacoes: action.payload.data };
    case 'GET_SETORES_ATENDIMENTOS':
      const { setoresAtendimentos } = action.payload.data;
      return { ...state, setoresAtendimento: setoresAtendimentos };
    case 'GET_PERIODOS_VENCIMENTOS':
      return { ...state, periodosVencimentos: action.payload.data };
    case 'GET_TIPOS_INSCRICOES':
      return { ...state, tiposInscricoes: action.payload.data };
    case 'GET_RAMOS_ATIVIDADES':
      return { ...state, ramosAtividades: action.payload.data };
    case 'GET_ATIVIDADES':
      return { ...state, atividades: action.payload.data.atividades };
    case 'GET_STATUS_OBRIGACOES':
      return { ...state, statusObrigacoes: action.payload.data };
    case 'SHOW_LOADING':
      return { ...state, showLoading: action.payload };
    case 'GET_ESTADOS':
      return { ...state, estados: action.payload.data.estados };
    case 'GET_ESCRITORIO_ATIVO':
      const [empresa] = action.payload.data.escritorios;
      return {
        ...state,
        escritorio: {
          logoTipo: empresa.logoTipo,
          razaoSocial: empresa.razaoSocial,
          nomeFantasia: empresa.nomeFantasia,
        },
      };
    case 'GET_EMPRESAS_CLIENTES':
      return { ...state, empresasClientes: action.payload.data.empresasClientes };
    case 'GET_TODAS_EMPRESAS_ATIVAS':
      return { ...state, todasEmpresasAtivas: action.payload.data.empresas };
    case 'DEFAULT_SET_CHECKED_USER':
      return { ...state, selectUser: action.payload };
    default:
      return state;
  }
}
