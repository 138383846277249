import moment from 'moment';
import { ALTERA_STATUS_FAVORITO_SUCCESS, FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_SUCCESS } from '../actions/types';

const INITIAL_STATE = {
  // Loading
  carregandoTarefas: false,
  carregandoObrigacoes: false,
  // Combos
  empresas: [],
  responsaveis: [],
  previsoesEntregas: [],
  atividades: [],
  // Empresa Aberta
  empresaAberta: null,
  // Mensagens
  mensagens: [],
  anexos: [],
  salvandoSolicitacoes: false,
  // Filtros
  filtroPeriodoVigencia: moment().format('MM/YYYY'),
  filtroPrevisaoEntrega: null,
  filtroEmpresas: [],
  filtroResponsaveis: [],
  filtroStatus: [],
  filtroAtividades: [],
  filtroSetoresAtendimentos: [],
  filtroTiposAtividades: [],
  // Modals
  showModalMensagem: false,
  showModalEncaminhar: false,
  pessoas: [],
  // Grafico
  dadosGrafico: [],
  periodoVigencia: '',
  obrigacoes: [],
  tarefas: [],
  filtrosDashboard: {
    periodoVigencia: null,
    empresaId: null,
    obrigacaoId: null,
    previsaoEntrega: null,
    empresas: [],
    responsaveis: [],
    status: [],
    atividades: [],
    setores: [],
  },
  empresasPeriodoVigencia: [],
  showModalComentario: false,
  comentarios: [],
  comentarioAnexos: [],
  isNotificarEmpresaLoading: false,
  showAtividadesConcluidas: false,
  pendencias: 0,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ESTADO_INICIAL':
      state = INITIAL_STATE;
      return { ...state };
    case 'SET_PERIODO_VIGENCIA':
      return { ...state, filtrosDashboard: { periodoVigencia: action.payload } };

    case 'ENVIAR_MENSAGEM':
      return { ...state, showModalMensagem: action.payload };
    case 'SET_EMPRESAS_PERIODO_VIGENCIA':
      return {
        ...state,
        empresasPeriodoVigencia: action.payload,
      };
    case 'SHOW_OR_HIDE_ATIVIDADES_CONCLUIDAS':
      return { ...state, showAtividadesConcluidas: action.payload };
    // Grafico
    case 'SET_GRAFICO_DASHBOARD':
      return { ...state, dadosGrafico: action.payload };
    // Empresa Aberta
    case 'SET_EMPRESA_ABERTA':
      return { ...state, empresaAberta: action.payload };
    // Filtros
    case 'SET_FILTRO_PERIODO_VIGENCIA':
      return { ...state, filtroPeriodoVigencia: action.payload };
    case 'SET_FILTRO_PREVISAO_ENTREGA':
      return { ...state, filtroPrevisaoEntrega: action.payload };
    case 'SET_FILTRO_EMPRESAS':
      return { ...state, filtroEmpresas: action.payload };
    case 'SET_FILTRO_RESPONSAVEIS':
      return { ...state, filtroResponsaveis: action.payload };
    case 'SET_FILTRO_STATUS':
      return { ...state, filtroStatus: action.payload };
    case 'SET_FILTRO_ATIVIDADES':
      return { ...state, filtroAtividades: action.payload };
    case 'SET_FILTRO_SETORES_ATENDIMENTOS':
      return { ...state, filtroSetoresAtendimentos: action.payload };
    case 'SET_FILTRO_TIPOS_ATIVIDADES':
      return { ...state, filtroTiposAtividades: action.payload };

    // Combos
    case 'GET_EMPRESAS':
      return { ...state, empresas: action.payload.data.empresas };
    case 'GET_RESPONSAVEIS':
      return { ...state, responsaveis: action.payload.colaboradorEmpresas };
    case 'GET_PREVISOES_ENTREGAS':
      return { ...state, previsoesEntregas: action.payload.data };
    case 'GET_ATIVIDADES': {
      return { ...state, atividades: action.payload.data.atividades };
    }
    // Obrigações
    case 'INICIAR_OBRIGACOES':
      return { ...state, carregandoObrigacoes: true };
    case 'COMPLETADO_OBRIGACOES':
      return {
        ...state,
        obrigacoes: action.payload,
        carregandoObrigacoes: false,
      };

    // Tarefas
    case 'INICIAR_TAREFAS':
      return { ...state, carregandoTarefas: true };
    case 'COMPLETADO_TAREFAS':
      return { ...state, tarefas: action.payload.tarefas, carregandoTarefas: false };

    // Modals
    // Solicitações
    case 'INICIAR_SOLICITACOES':
      return { ...state, salvandoSolicitacoes: true };
    case 'COMPLETADO_SOLICITACOES':
      return { ...state, mensagens: action.result.data.mensagens, salvandoSolicitacoes: false };
    case 'GET_MENSAGEM':
      return { ...state, mensagens: action.payload.mensagens };
    case 'GET_PESSOAS_ESCRITORIO_EXCETO_PESSOA':
      return { ...state, pessoas: action.payload };
    case 'SHOW_MODAL_MENSAGEM':
      return { ...state, showModalMensagem: action.payload };
    case 'SHOW_MODAL_ENCAMINHAR':
      return { ...state, showModalEncaminhar: action.payload };
    case 'SET_LISTA_ANEXOS':
      return { ...state, anexos: action.payload };
    case 'DASH_GET_COMENTARIOS':
      return { ...state, comentarios: action.payload.data.comentarios };
    case 'DASH_SHOW_MODAL_COMENTARIO':
      return { ...state, showModalComentario: action.payload };
    case 'DASH_SET_LISTA_COMENTARIO_ANEXOS':
      return { ...state, comentarioAnexos: action.payload };
    case 'DASH_NOTIFICAR_EMPRESA_LOADING':
      return { ...state, isNotificarEmpresaLoading: action.payload };
    case ALTERA_STATUS_FAVORITO_SUCCESS:
      return { ...state, empresasPeriodoVigencia: action.payload };
    case FETCH_TOTAL_EMPRESAS_OBRIGACOES_PENDENCIAS_SUCCESS:
      return { ...state, pendencias: action.payload };
    default:
      return state;
  }
}
