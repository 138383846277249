import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const Header = ({ history, title, father, fatherLink, children }) => (
  <div className="row wrapper border-bottom white-bg page-heading">
    <div className="col-sm-8">
      <h2>{title}</h2>
      <ol className="breadcrumb">
        <li>
          <Link to="/home">Home</Link>
        </li>

        {father && (
          <li>
            <a href={`#${fatherLink}`}>{father}</a>
          </li>
        )}

        <li className="active">
          <strong>{title}</strong>
        </li>
      </ol>
    </div>
    <div className="col-sm-4">
      <div className="title-action">
        {children}

        <button
          type="button"
          style={{ marginLeft: 4 }}
          className="btn btn-warning btn-sm"
          onClick={() => history.goBack()}
        >
          Voltar
        </button>
      </div>
    </div>
  </div>
);

export default withRouter(Header);
