import { HubConnectionBuilder } from '@microsoft/signalr';
import notificacao from '../api/notificacao';
import notificacaoURLs from '../util/notificacaoURLs';
import {
  ADICIONA_NOTIFICACAO,
  ATUALIZA_NOTIFICACOES,
  FETCH_NOTIFICACOES_FAIL,
  FETCH_NOTIFICACOES_SUCCESS,
  MARCAR_NOTIFICACAO_LIDO_FAIL,
  MARCAR_NOTIFICACAO_LIDO_SUCCESS,
  REGISTRA_NOTIFICACAO_FAIL,
  REGISTRA_NOTIFICACAO_SUCCESS,
  TOTAL_NOTIFICACAO_NAO_LIDA,
} from './types';
import _ from 'lodash';
import lowerFirstCase from '../util/lowerFirstCase';

export const signalR = () => (dispatch, getState) => {
  const {
    usuario: {
      pessoaLogada: { usuarioId, tenantId },
    },
  } = getState();

  const hubConnection = new HubConnectionBuilder()
    .withUrl(notificacaoURLs[process.env.NODE_ENV] + '/echo')
    .withAutomaticReconnect()
    .build();

  hubConnection.on('RecebeNotificacaoTenant', data => addNotificacao(data, dispatch));
  hubConnection.on('RecebeNotificacaoUsuarios', data => addNotificacao(data, dispatch));
  hubConnection.on('RecebeNotificacaoModulo', data => addNotificacao(data, dispatch));

  hubConnection
    .start()
    .then(() => {
      hubConnection.invoke('AddToGroup', `${tenantId}${usuarioId}`);
      hubConnection.invoke('AddToGroup', `${tenantId}`);
      hubConnection.invoke('AddToGroup', 'Tron Smart');
    })
    .catch(() => dispatch({ type: REGISTRA_NOTIFICACAO_FAIL }));

  getPermissaoNotificacao();
  dispatch({ type: REGISTRA_NOTIFICACAO_SUCCESS });
  // dispatch(getNotificacoes());
};

const addNotificacao = (notificacao, dispatch) => {
  dispatch({ type: ADICIONA_NOTIFICACAO, payload: notificacao });
  enviaNotificacao(notificacao);
};

export const getNotificacoes = (page = 1) => async (dispatch, getState) => {
  const {
    usuario: {
      pessoaLogada: { tenantId },
    },
  } = getState();

  const params = {
    tenantId,
    page,
    modulo: 1,
    limit: 5,
  };

  try {
    const { data } = await notificacao.get('/notificacoes', { params });
    dispatch({ type: FETCH_NOTIFICACOES_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: FETCH_NOTIFICACOES_FAIL });
  }
};

export const marcarLido = id => async (dispatch, getState) => {
  const {
    notificacao: { notificacoes, notificacoesNaoLidas, totalNotificacoes },
  } = getState();

  try {
    const { data } = await notificacao.put(`/notificacoes/${id}/marcar-lido`);
    const updatedNotificacoes = notificacoes.map(item => (item.id === id ? data : item));

    dispatch({
      type: ATUALIZA_NOTIFICACOES,
      payload: {
        data: updatedNotificacoes,
        notificationsCount: notificacoesNaoLidas - 1,
        count: totalNotificacoes,
      },
    });

    dispatch({ type: MARCAR_NOTIFICACAO_LIDO_SUCCESS });
  } catch (err) {
    dispatch({ type: MARCAR_NOTIFICACAO_LIDO_FAIL });
  }
};

const enviaNotificacao = notificacao => {
  if (Notification.permission === 'granted') {
    const [nomeRemetente] = notificacao.remetente.nome.split(' ');
    const body = `${_.startCase(nomeRemetente.toLowerCase())} ${lowerFirstCase(
      notificacao.mensagem,
    )}`;

    const opcoes = { body };
    const notification = new Notification('Tron Smart', opcoes);
  }
};

const getPermissaoNotificacao = () => {
  if (Notification.permission !== 'denied') {
    Notification.requestPermission();
  }
};
