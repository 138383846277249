import AcessoApp from 'ajuda-inicializacao';

import CaptchaCnd from './../pages/certidaoNegativaDebito/captchaCnd';

const INITIAL_STATE = {
    rotas: [
        {
            path: '/acesso-app',
            exact: true,
            component: AcessoApp,
            permitido: true
        },
        {
            path: '/captcha',
            exact: true,
            component: CaptchaCnd,
            permitido: true
        }
    ],
    menus: []
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'ROT_GET_ROTAS_PERMITIDAS':
            return { ...state, rotas: action.payload.data.rotasPermitidas }
        case 'ROT_SET_ROTAS_PERMITIDAS':
            return { ...state, rotas: action.payload }
        case 'ROT_SET_MENUS':
            return { ...state, menus: action.payload }
        default:
            return state;
    }
}