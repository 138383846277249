import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';

import sentinela from '../api/sentinela';
import Util from '../helpers/util';

export const carregaAutenticacao = async (token, tenant) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tenant', tenant);
};

export const logOutUser = () => {
  sessionStorage.clear();
};

export const setEmpresas = payload => ({
  type: 'DFT_SET_EMPRESAS',
  payload,
});

export const setNomeEmpresa = payload => ({
  type: 'DFT_SET_EMPRESAS_NOME',
  payload,
});

export const showModal = () => ({
  type: 'DFT_SET_MODAL_VISIBLE',
  payload: true,
});

export const closeModal = () => ({
  type: 'DFT_SET_MODAL_VISIBLE',
  payload: false,
});

export function getTiposPessoas() {
  const request = axios.get(`${Util.getBaseUrl()}/tipos-pessoas`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_PESSOAS',
    payload: request,
  };
}

export function getCidades(description, pageIndex) {
  const request = axios.get(
    `${Util.getBaseUrl()}/cidades?description=${description}&pageIndex=${pageIndex}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_CIDADES',
    payload: request,
  };
}

export function getEstados(description, pageIndex) {
  const request = axios.get(`${Util.getBaseUrl()}/cidades/estados`, Util.getHeaders());
  return {
    type: 'GET_ESTADOS',
    payload: request,
  };
}

export function getCnaes(description, pageIndex) {
  const request = axios.get(
    `${Util.getBaseUrl()}/cnaes?description=${description}&pageIndex=${pageIndex}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_CNAES',
    payload: request,
  };
}

export function getNaturezasJuridicas(description, pageIndex) {
  const request = axios.get(
    `${Util.getBaseUrl()}/naturezas-juridicas?description=${description}&pageIndex=${pageIndex}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_NATUREZAS_JURIDICAS',
    payload: request,
  };
}

export function getRamosAtividades() {
  const request = axios.get(`${Util.getBaseUrl()}/ramos-atividades`, Util.getHeaders());
  return {
    type: 'GET_RAMOS_ATIVIDADES',
    payload: request,
  };
}

export function getRegimesTributacoes() {
  const request = axios.get(`${Util.getBaseUrl()}/regimes-tributacoes`, Util.getHeaders());
  return {
    type: 'GET_REGIMES_TRIBUTACOES',
    payload: request,
  };
}

export function getAtividades() {
  const request = axios.get(`${Util.getBaseUrl()}/atividades`, Util.getHeaders());
  return {
    type: 'GET_ATIVIDADES',
    payload: request,
  };
}

export function getTiposAtividades() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/tipos-atividades`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_ATIVIDADES',
    payload: request,
  };
}

export function getCriticidades() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/criticidades`, Util.getHeaders());
  return {
    type: 'GET_CRITICIDADES',
    payload: request,
  };
}

export function getPeriodicidades() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/periodicidades`, Util.getHeaders());
  return {
    type: 'GET_PERIODICIDADES',
    payload: request,
  };
}

export function getTiposVencimentos() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/tipos-vencimentos`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_VENCIMENTOS',
    payload: request,
  };
}

export function getTiposTratativasVencimentos() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/tipos-tratativas-vencimentos`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_TRATATIVAS_VENCIMENTOS',
    payload: request,
  };
}

export function getTiposResponsaveis() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/tipos-responsaveis`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_RESPONSAVEIS',
    payload: request,
  };
}

export function getTiposOperacoes() {
  const request = axios.get(`${Util.getBaseUrl()}/tipos-operacoes`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_OPERACOES',
    payload: request,
  };
}

export function getSetoresAtendimentos() {
  const request = axios.get(`${Util.getBaseUrl()}/setores-atendimentos`, Util.getHeaders());
  return {
    type: 'GET_SETORES_ATENDIMENTOS',
    payload: request,
  };
}

export function getPeriodosVencimentos() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/periodos-vencimentos`, Util.getHeaders());
  return {
    type: 'GET_PERIODOS_VENCIMENTOS',
    payload: request,
  };
}

export function getTiposInscricoes() {
  const request = axios.get(`${Util.getBaseUrl()}/tipos-inscricoes`, Util.getHeaders());
  return {
    type: 'GET_TIPOS_INSCRICOES',
    payload: request,
  };
}

export function getStatusObrigacoes() {
  const request = axios.get(`${Util.getBaseUrl()}/enumeradores/status-obrigacao`, Util.getHeaders());
  return {
    type: 'GET_STATUS_OBRIGACOES',
    payload: request,
  };
}

export function showLoading(show) {
  return {
    type: 'SHOW_LOADING',
    payload: show,
  };
}

export function getEscritorioAtivo(description, pageIndex) {
  const desc = description ? desc : '';
  const request = axios.get(
    `${Util.getBaseUrl()}/escritorios?description=${desc}&pageIndex=${pageIndex}`,
    Util.getHeaders(),
  );
  return {
    type: 'GET_ESCRITORIO_ATIVO',
    payload: request,
  };
}

export function getEmpresasClientes() {
  const request = axios.get(`${Util.getBaseUrl()}/empresas/empresas-clientes`, Util.getHeaders());
  return {
    type: 'GET_EMPRESAS_CLIENTES',
    payload: request,
  };
}

export function getTodasEmpresasAtivas() {
  const request = axios.get(`${Util.getBaseUrl()}/empresas/ativas/${true}`, Util.getHeaders());
  return {
    type: 'GET_TODAS_EMPRESAS_ATIVAS',
    payload: request,
  };
}

export function changeUser(checked, form) {
  return dispatch => {
    dispatch({ type: 'DEFAULT_SET_CHECKED_USER', payload: checked });
    if (!checked) {
      dispatch(change(form, 'usuarioId', null));
    }
  };
}

export const buscarEmpresas = () => async (dispatch, getState) => {
  const { usuario } = getState();

  try {
    const { data } = await sentinela.get(`/usuarios/${usuario.pessoaLogada.usuarioId}/tenants`);

    const tenantId = sessionStorage.getItem('tenant');

    const nomeBaseDados = data.tenants.find(tenant => tenant.id === tenantId).nome;

    dispatch([
      setEmpresas(data ? _.filter(data.tenants, tenant => tenant.modulo === 1) : []),
      setNomeEmpresa(nomeBaseDados),
    ]);
  } catch (error) {
    toastr.error('Empresas', 'Ocorreu um erro ao carregar as Empresas!');
  }
};

export const trocarEmpresa = id => dispatch => {
  const token = sessionStorage.getItem('token');

  dispatch([logOutUser(), carregaAutenticacao(token, id), window.location.reload()]);
};
