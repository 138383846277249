import {
  FETCH_CLIENTES_SUCCESS,
  FETCH_COMENTARIOS_SUCCESS,
  FETCH_MENSAGENS_SUCCESS,
  FETCH_PESSOAS_ESCRITORIO_SUCCESS,
  SET_ATIVIDADES_DRAWER_VISIBILE,
  SET_CURRENT_ROW,
  SET_ALL_ATIVIDADES_EMPRESA_SELECIONADA,
  FETCH_SOLICITACAO_SUCCESS,
  FETCH_RESPONSAVEIS_MOVIMENTACAO_SUCCESS,
  FETCH_PENDENCIAS_SUCCESS,
  VERIFICA_ATIVIDADE_EXCLUIVEL_SUCCESS,
  LOADING_OBRIGACOES_START,
  LOADING_OBRIGACOES_DONE
} from '../actions/types';

const INITIAL_STATE = {
  comentarios: [],
  clientes: [],
  colaboradores: [],
  mensagens: [],
  isDrawerVisible: false,
  currentRow: {},
  empresaSelecionadaAtividades: [],
  solicitacao: {},
  responsaveis: [],
  pendencias: [],
  atividadeExcluivel: true,
  loadingObrigacoes: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOADING_OBRIGACOES_START:
      return { ...state, loadingObrigacoes: true };
    case LOADING_OBRIGACOES_DONE:
      return { ...state, loadingObrigacoes: false };
    case FETCH_COMENTARIOS_SUCCESS:
      return { ...state, comentarios: action.payload };
    case FETCH_CLIENTES_SUCCESS:
      return { ...state, clientes: action.payload };
    case FETCH_PESSOAS_ESCRITORIO_SUCCESS:
      return { ...state, colaboradores: action.payload };
    case FETCH_MENSAGENS_SUCCESS:
      return { ...state, mensagens: action.payload };
    case SET_ATIVIDADES_DRAWER_VISIBILE:
      return { ...state, isDrawerVisible: action.payload };
    case SET_CURRENT_ROW:
      return { ...state, currentRow: action.payload };
    case SET_ALL_ATIVIDADES_EMPRESA_SELECIONADA:
      return { ...state, empresaSelecionadaAtividades: action.payload };
    case FETCH_SOLICITACAO_SUCCESS:
      return { ...state, solicitacao: action.payload };
    case FETCH_RESPONSAVEIS_MOVIMENTACAO_SUCCESS:
      return { ...state, responsaveis: action.payload };
    case FETCH_PENDENCIAS_SUCCESS:
      return { ...state, pendencias: action.payload };
    case VERIFICA_ATIVIDADE_EXCLUIVEL_SUCCESS:
      return { ...state, atividadeExcluivel: action.payload };
    default:
      return state;
  }
}
