import { toastr } from 'react-redux-toastr';

import sentinela from '../api/sentinela';
import routeComponents from '../util/routeComponents';
import setRotasNaoPesquisaveis from '../util/setRotasNaoPesquisaveis';

export const setRotasPermitidas = rotas => {
    return {
        type: 'ROT_SET_ROTAS_PERMITIDAS',
        payload: rotas
    }
}

export const setMenus = menus => {
    return {
        type: 'ROT_SET_MENUS',
        payload: menus
    }
}

/**
 * Function to search for allowed routes. 
 */
export const getRotasPermitidas = () => async (dispatch, getState) => {
    const { usuario: { pessoaLogada } } = getState();
    try {
        const { data } = await sentinela.get(`/rotas/rotas-permitidas/1`);
        const listaRotas = data.rotas.map(rota => ({
            ...rota,
            component: routeComponents[rota.path],
            isSearchable: setRotasNaoPesquisaveis(rota.path),
            exact: true
        }));

        const rotas = pessoaLogada.tipo === 'Cliente'
            ? defineClienteRootRoute(listaRotas)
            : listaRotas;

        return dispatch([
            setRotasPermitidas([...ROTAS_DEFAULT, ...rotas]),
            setMenus(data.menus)
        ]);
    } catch (err) {
        // if (err.response.data.notificacoes) { err.response.data.notificacoes.forEach(e => toastr.error('Busca Rotas', `${e.mensagem}`, { timeOut: 8000 })); }
        // else { toastr.error('Busca Rotas', `Opps... Erro ao tentar buscar as rotas \n Erro: ${err.message}`); }
        toastr.error('Busca Rotas', `Opps... Erro ao tentar buscar as rotas \n Erro: ${err.message}`);
    }
};
/**
 * Function to search for allowed routes. 
 */
export const getRotasIniciais = () => async (dispatch) => {
    try {
        dispatch(setRotasPermitidas(...ROTAS_DEFAULT));
    } catch (err) {
        if (err.response.data.notificacoes) { err.response.data.notificacoes.forEach(e => toastr.error('Busca Rotas', `${e.mensagem}`, { timeOut: 8000 })); }
        else { toastr.error('Busca Rotas', `Opps... Erro ao tentar buscar as rotas \n Erro: ${err.message}`); }
    }
};

const defineClienteRootRoute = (listaRotas) => {
    return listaRotas.map(rota => {
        if(rota.path !== '/' && rota.path !== '/home') return rota;

        return {
            ...rota,
            component: routeComponents['/solicitacoes'],
            permitido: true
        }
    });
};

const ROTAS_DEFAULT = [
    {
        path: '/acesso-nao-autorizado',
        component: routeComponents['/acesso-nao-autorizado'],
        permitido: true,
        exact: true 
    },
    {
        path: '/edit-info-pessoa-empresa/:id/:empresaId',
        component: routeComponents['/edit-info-pessoa-empresa/:id/:empresaId'],
        exact: true,
        permitido: true
    },
    {
        path: '/edit-info-pessoa-escritorio/:id/:empresaId',
        component: routeComponents['/edit-info-pessoa-escritorio/:id/:empresaId'],
        exact: true,
        permitido: true
    },
    {
        path: '/pesquisa',
        component: routeComponents['/pesquisa'],
        extact: true,
        permitido: true
    },
];
