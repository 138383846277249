const INITIAL_STATE = {
  setoresAtendimentos: [],
  pessoasEscritorios: [],
  carregandoSetores: false,
  desabilitaDescricaoSetor: false,
  carregandoForm: false,
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_ESTADO_INICIAL':
      return {
        ...state,
        setoresAtendimentos: [],
        pessoasEscritorios: [],
        carregandoSetores: false,
        desabilitaDescricaoSetor: false,
      };
    case 'SA_GET_PESSOAS_ESCRITORIOS':
      return { ...state, pessoasEscritorios: action.payload };
    case 'INICIAR_SETORES':
      return { ...state, carregandoSetores: true };
    case 'COMPLETADO_SETORES':
      return {
        ...state,
        setoresAtendimentos: action.result.data.setoresAtendimentos,
        carregandoSetores: false,
      };
    case 'SET_DESABILITA_DESCRICAO':
      return { ...state, desabilitaDescricaoSetor: action.payload };
    case 'SA_CARREGANDO_SETOR_FORM':
      return { ...state, carregandoForm: action.payload };
    default:
      return state;
  }
}
