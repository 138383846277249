import React, { Suspense } from 'react';

import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import NotFound from '../pages/NotFound';
import sentinelaFront from '../util/sentinelaFront';
import RouteWithSubRoutes from './RouteWithSubRoutes';

class Routers extends React.PureComponent {
  verificaToken(props) {
    const { origin } = window.location;
    const redirectURL = `redirectURL=${origin}&toLocation=${props.location.pathname}`;
    const token = sessionStorage.getItem('token');

    return token ? (
      <NotFound {...props} />
    ) : (
      window.location.assign(
        `${sentinelaFront[process.env.NODE_ENV]}/#/login?${redirectURL}&fromApplication=1`,
      )
    );
  }

  render() {
    return (
      <Suspense fallback={<div>Carregando...</div>}>
        <HashRouter>
          <Switch>
            {this.props.rota.rotas.map(route => (
              <RouteWithSubRoutes key={route.path} {...route} />
            ))}
            <Route path="*" render={this.verificaToken} />
          </Switch>
        </HashRouter>
      </Suspense>
    );
  }
}

const mapStateToProps = state => ({ rota: state.rota });

export default connect(mapStateToProps)(Routers);
