import React from 'react';

import EmptyNotification from '../../theme/assets/img/empty_notification.png';

export default function NotificacaoVazio() {
  return (
    <div className="notificacao-empty">
      <img src={EmptyNotification} />
      <div className="notificacao-title">Sem notificações</div>
      <div className="notificacao-message">Quando você receber alguma notificação ela aparecerá aqui.</div>
    </div>
  );
}
