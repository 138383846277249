import React from 'react';

import { Tooltip } from 'antd';
import $ from 'jquery';
import { connect } from 'react-redux';

import { logOutUser, showModal } from '../actions/defaultActions';
import { pesquisaRota } from '../actions/topHeaderActions';
import Notificacao from '../components/Notificacao';
import sentinelaFront from '../util/sentinelaFront';
import { smoothlyMenu } from './helpers/helpers';

class TopHeader extends React.Component {
  toggleNavigation(e) {
    e.preventDefault();
    $('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  handleKeyPress(e) {
    if (e.key !== 'Enter') return;

    const { searchAction } = this.props;

    const pesquisa = e.target.value;
    searchAction(pesquisa);
  }

  render() {
    const {
      default: { basesDados, basesDadosNome },
      usuario: { pessoaLogada },
      logoutAction,
      showModalAction,
    } = this.props;

    const routePessoa = pessoaLogada.isEscritorio ? 'escritorio' : 'empresa';

    return (
      <div className="row border-bottom">
        <nav
          className="navbar navbar-static-top"
          role="navigation"
          style={{ marginBottom: 0, zIndex: 'auto' }}
        >
          <div className="navbar-header">
            <a
              className="minimalize-styl-2 btn btn-primary"
              onClick={e => this.toggleNavigation(e)}
              href="#"
            >
              <i className="fa fa-bars" />
            </a>
            {pessoaLogada.isEscritorio && (
              <div role="search" className="navbar-form-custom">
                <div className="form-inline" style={{ marginTop: 10 }}>
                  <div className="form-group">
                    <div className="input-group">
                      <div className="input-group-addon">
                        <i className="fa fa-search" />
                      </div>
                      <input
                        type="text"
                        placeholder="Faça sua pesquisa..."
                        onKeyPress={e => this.handleKeyPress(e)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <ul className="nav navbar-top-links navbar-right">
            <li>
              <span className="m-r-sm text-muted welcome-message">{basesDadosNome}</span>
            </li>
            {basesDados.length > 1 && (
              <li>
                <Tooltip title="Trocar Empresa">
                  <a onClick={showModalAction}>
                    <i className="fa fa-retweet" />
                  </a>
                </Tooltip>
              </li>
            )}
            <li>
              <Notificacao />
            </li>
            <li>
              <div style={{ padding: '20px 10px' }}>
                <div className="dropdown c-pointer">
                  <span
                    className="fa fa-caret-down fa-lg dropdown-toggle grey"
                    id="optionsMenu"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                   />
                  <ul className="dropdown-menu mt-1" aria-labelledby="optionsMenu">
                    <li>
                      <a
                        href={`#/edit-info-pessoa-${routePessoa}/${pessoaLogada.id}/${pessoaLogada.empresaId}`}
                      >
                        <i className="fa fa-user" /> Meu Perfil
                      </a>
                    </li>
                    <li>
                      <a href={`${sentinelaFront[process.env.NODE_ENV]}/#/`} onClick={logoutAction}>
                        <i className="fa fa-sign-in" /> Acessar meus produtos
                      </a>
                    </li>
                    <li role="separator" className="divider" />
                    <li>
                      <a
                        href={`${sentinelaFront[process.env.NODE_ENV]}/#/logout`}
                        onClick={logoutAction}
                      >
                        <i className="fa fa-sign-out" /> Sair
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario,
  default: state.default,
});
const mapDispatchToProps = {
  logoutAction: logOutUser,
  searchAction: pesquisaRota,
  showModalAction: showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
