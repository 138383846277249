import { Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { marcarLido } from '../../actions/notificacaoActions';
import lowerFirstCase from '../../util/lowerFirstCase';

const renderProfileImage = ({ remetente }) => {
  if (remetente && 'foto' in remetente && remetente.foto)
    return <img src={remetente.fotoUrl} alt="Avatar" className="avatar" />;

  return <div className="no-profile avatar">{remetente.nome.charAt(0)}</div>;
};

const NotificacaoItem = ({ item, marcarLidoAction }) => {
  const isNova = item.lidoEm === 0 || !('lidoEm' in item);
  const [nomeRemetente] = item.remetente.nome.split(' ');

  return (
    <div className={`notificacao-item ${isNova ? 'nova-notificacao' : ''}`}>
      <div className="avatar-container">{renderProfileImage(item)}</div>
      <div className="msg-container">
        <span className="fw-700">{_.startCase(nomeRemetente.toLowerCase())}</span>&nbsp;
        <span>{lowerFirstCase(item.mensagem)}</span>
        <div className="notificacao-data">
          <div>{moment.unix(item.enviadoEm).fromNow()}</div>
          {isNova && (
            <Tooltip placement="bottom" title="Marcar como lido">
              <div className="dot" onClick={() => marcarLidoAction(item.id)} />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(
  null,
  { marcarLidoAction: marcarLido },
)(NotificacaoItem);
