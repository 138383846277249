import React, { Fragment } from 'react';

import $ from 'jquery';
import { connect } from 'react-redux';

import { getEscritorioAtivo } from '../../actions/defaultActions';
import { getRotasPermitidas } from '../../actions/rotaActions';
import { getPessoaLogada, setEstadoInicialUsuarioLogado } from '../../actions/usuarioActions';
import URL_BASE_AZURE from '../../util/AzureURL';
import miniLogo from '../assets/img/navigation/nav-header-mini.png';
import DrawerEmpresa from '../components/drawerEmpresa';
import MenuItem from '../menuItem';
import MenuTree from '../menuTree';

class Navigation extends React.Component {
  componentDidMount() {
    this.props.getEscritorioAtivo();
  }

  componentDidUpdate() {
    const { menu } = this.refs;
    if (this.props.rota.menus.length > 0) {
      $(() => {
        $(menu).metisMenu({
          toggle: true,
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.setEstadoInicialUsuarioLogado();
  }

  renderMenuTree(menu) {
    return (
      <MenuTree key={menu.funcaoId} icon={menu.icone} label={menu.funcaoDescricao}>
        {menu.menus.map((item) => {
          if (item.menus && item.menus.length > 0) {
            this.renderMenuTree(item);
          } else {
            return this.renderMenuItemTree(item);
          }
        })}
      </MenuTree>
    );
  }

  renderMenuItemTree(menu) {
    return (
      <MenuItem
        key={menu.funcaoId}
        path={`#${menu.rota}`}
        icon={menu.icone}
        label={menu.funcaoDescricao}
        tree
      />
    );
  }

  renderMenuItem(menu) {
    return (
      <MenuItem
        key={menu.funcaoId}
        path={`#${menu.rota}`}
        icon={menu.icone}
        label={menu.funcaoDescricao}
      />
    );
  }

  render() {
    const {
      usuario: { pessoaLogada },
      default: { escritorio },
      rota: { menus },
    } = this.props;


    let urlFoto;

    if (pessoaLogada.foto != null) {
      urlFoto = pessoaLogada.foto.trim() || 'perfil/nav-header-mini.png';
    } else {
      urlFoto = 'perfil/nav-header-mini.png';
    }
    return (
      <>
        <nav className="navbar-default navbar-static-side" role="navigation">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu" ref="menu" style={{ zIndex: 2000 }}>
              <div className="dropdown profile-element">
                {escritorio.logoTipo && pessoaLogada.tipo === 'Cliente' && (
                  <div style={{ margin: 10 }}>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              <img
                                alt="logoTipo"
                                className="img-circle"
                                style={{ width: 32, height: 32 }}
                                src={escritorio.logoTipo}
                              />
                            </span>
                          </td>
                          <td style={{ wordWrap: 'break-word' }}>
                            <p className="truncate-cliente-escritorio">
                              {escritorio.nomeFantasia
                                ? escritorio.nomeFantasia
                                : escritorio.razaoSocial}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <li className="nav-header">
                <div className="row">
                  <div className="dropdown profile-element" style={{ textAlign: 'center' }}>
                    <span className="profile-img-container">
                      <img
                        alt="fotoPessoa"
                        className="img-circle img-md img-center"
                        style={{ width: 80, height: 80, objectFit: 'cover' }}
                        src={`${URL_BASE_AZURE}/documentos/${urlFoto}`}
                      />
                    </span>
                    <span className="clear">
                      <span className="block m-t-xs">
                        <strong className="font-bold">{pessoaLogada.nome}</strong>
                      </span>
                      <span className="block m-t-xs">
                        <small>{pessoaLogada.empresa}</small>
                      </span>
                      <span className="text-muted text-xs block">{pessoaLogada.tipo}</span>
                    </span>
                  </div>
                </div>
                <div className="logo-element">
                  <img alt="minilogo" className="img-sm" src={miniLogo} />
                </div>
              </li>
              {/* menu */}
              {menus.map((menu) => {
                if (menu.permitido) {
                  if (menu.menus && menu.menus.length > 0) {
                    return this.renderMenuTree(menu);
                  }
                  return this.renderMenuItem(menu);
                }
              })}
            </ul>
          </div>
        </nav>

        <DrawerEmpresa />
      </>
    );
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario,
  default: state.default,
  rota: state.rota,
});

const mapDispatchToProps = {
  getPessoaLogada,
  getEscritorioAtivo,
  setEstadoInicialUsuarioLogado,
  getRotasPermitidas,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navigation);
