import { Drawer } from 'antd';
import List from 'devextreme-react/list';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { buscarEmpresas, closeModal, trocarEmpresa } from '../../actions/defaultActions';

const DrawerEmpresa = ({
  getAction,
  closeAction,
  changeTenantAction,
  default: { basesDados, modalVisible },
}) => {
  useEffect(() => {
    getAction();
  }, [getAction]);

  const itemRender = ({ nome, id }) => (
    <div key={id} className="tron-primary">
      <div className="row">
        <div
          className="col-md-10"
          style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
        >
          <a>
            <span>{nome}</span>
          </a>
        </div>
        <div>
          <a>
            <i className="fa fa-sign-in fa-2x" />
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <Drawer
      title="Seleção de Empresas"
      placement="right"
      closable={false}
      width={500}
      onClose={closeAction}
      visible={modalVisible}
    >
      <List
        dataSource={basesDados}
        itemRender={itemRender}
        searchExpr="nome"
        searchEnabled
        onItemClick={({ itemData }) => changeTenantAction(itemData.id)}
      />
    </Drawer>
  );
};

const mapStateToProps = state => ({ default: state.default });

const mapDispatchToProps = {
  getAction: buscarEmpresas,
  changeTenantAction: trocarEmpresa,
  closeAction: closeModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrawerEmpresa);
