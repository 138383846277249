const INITIAL_STATE = {
    registros: [],
    dadosOperacao: {},
    modificacoes: {},
    exibeModal: false
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'REGISTROS_AUDITORIA':
            return { ...state, registros: action.payload.data.registros }
        case 'REGISTRO_AUDITORIA_POR_ID':
            return { ...state, dadosOperacao: action.payload.data.dadosOperacao, modificacoes: action.payload.data.modificacoes }
        case 'EXIBIR_MODAL_AUDITORIA':
            return { ...state, exibeModal: action.payload }
        default:
            return state
    }
}